import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import arrowDownRed from '../../../assets/icons/arrowDownRed.svg'
import LoadingSpinner from '../../../components/Spinner/index';
import Button from '../../../components/Button/index';
import Tab from 'react-bootstrap/Tab';
import Pivot from "./PivotTable";
import HealthProgressBar from '../../../components/HealthProgressBar/ProgressBar'
import Tabs from 'react-bootstrap/Tabs';
import TextField from '../../../components/TextField/index'
import Title from "../../../components/Typorgraphy/Title"
import SearchableSelect from "../../../components/SearchAbleSelect/searchAbleSelect";
import Modal from 'react-bootstrap/Modal';
import Label from "../../../components/Typorgraphy/Label";
import LinkLabel from "../../../components/Typorgraphy/AnchorText";
import Paragraph from "../../../components/Typorgraphy/Paragraph";
import { useLocation } from 'react-router-dom';
import { downloadPortfolioById,downloadPortfolioOutput, getCSVMatch,getMatchings, getPortfolioHealthById,getPortfolioMatch } from '../../../api/csv';
import editIcon from '../../../assets/icons/edit.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import filtericon from '../../../assets/icons/filter.svg';
import calenderIcon from '../../../assets/icons/calendar.svg';
import switchVerticalIcon from '../../../assets/icons/switch-vertical-02.svg';
import tableIcon from '../../../assets/icons/table.svg';
import filterFunnelIcon from '../../../assets/icons/filter-funnel-02.svg';
import messagePlusCircleIcon from '../../../assets/icons/message-plus-circle.svg';
import downloadIcon from '../../../assets/icons/download.svg';
import Formats from "../../../components/PageComponents/Settings/Formats";
import DataTable from "../../../components/DataTable";
import DataHealthTable from "../../../components/DataTable/DataHealthTable";
import JSZip from 'jszip';

import knownColumns from './knownColumns.json'
import downloadRight from '../../../assets/images/downloadRight.svg'

import './style.css'






const ListingDetails = () => {

    const [calculatedFieldModal,setCalculatedFieldModal] = useState(false);

    const openCalulatedFieldModal = (item) => {
        console.log("++++++ ITEM +++++++\n", {item})
        setCalculatedFieldModal(true);
    }
   
    const navigate = useNavigate();
    const [iframeLoading, setIframeLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);

    const handleClose = () => {
         setShow(false)
         setSelectedRow(null);
         setExpandedRows([]); 
        };

  const handleShow = (row) => {
    console.log("Opening Modal ")
     setShow(true)
     setSelectedRow(row);
    setExpandedRows([row.id]); 
    };

    const handleLoad = () => {
      setIframeLoading(false);
    };
    
    const [dataMatching, setDataMatching] = useState([])
    const [PCFFormat, setPCFFormat] = useState(null)
    const [lendAbleFormat,setlendableFormat] = useState(null)
    const [dataDropDownMatching, setDataDropDownMatching] = useState([])
    const [creditCurveFields,setCreditCurveFields] = useState([])
    const [yourField,setYourField] = useState(null)

    const location = useLocation();
    const dataState = location.state;
    const [selectedFormat, setSelectedFormat] = useState(null);
    const [formats, setFormats] = useState([])
    const [searchText, setSearchtext] = useState('')
    const [portfolioId, setPortfolioId] = useState(null);

    const [dataSummaryFields, setDataSummaryFields] = useState([]);
    const [dataHealthFields, setDataHealthFields] = useState([]);
    const [loading, setLoading] = useState(false);


   
    const customWidthsDataMatching = {
        'Original Field': '20%',
        'CreditCurve Field': '20%',
        'A.I Description': '30%',
        'Your Field': '30%',
    };


    const columnsMatching = [ 
        { name :'Original Field', selector: row => row.csvColumn,sortable: false},
        { name :'CreditCurve Field', selector: row => row.matchedColumn,sortable: false},
        { name :'A.I Description', selector: row => row.description,sortable: false},
        { name :'Your Field', selector: row => row.yourField,sortable: false},
      
     ]
     const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ];

    const customWidthsDataHealth = {
        'File Name': '20%',
        'Valid': '15%',
        'Inconsistent': '15%',
        'Invalid': '15%',
        'Missing': '15%',
        'Data Health': '20%',
    };

    const NoDataComponent = () => (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h5>Data Health Calculation in progress, Please wait a few minutes . . . </h5>
          <ProgressBar style={{backgroundColor:'#7F56D9'}} animated now={100} />
        </div>
      );

    const columnsDataHealth = [
        { name: 'File Name', selector: row => row.filename, sortable: false },
        { name: 'Valid', selector: row => row.valid, sortable: false },
        { name: 'Invalid', selector: row => row.invalid, sortable: false },
        { name: 'Inconsistent', selector: row => row.inconsistent, sortable: false },
        { name: 'Missing', selector: row => row.missing, sortable: false },
        { name: 'Data Health', selector: row => (
           
              <HealthProgressBar progress={row.dataHealth}  />
           
          ) , sortable: false },
    ];

    const dataForDataHealth = dataHealthFields.map((item,index) => ({
        id:index,
        filename: item.filename.replace("portfolio_csv_files/",""),
        valid: item.valid,
        inconsistent: item.inconsistent,
        invalid: item.invalid,
        missing: item.missing,
        dataHealth: item.dataHealth.toFixed(2),
        columns: item.columns
    }))

    const columnsExpandedHealth = [
        { name: 'Field Name', selector: row => row.columnName, sortable: false },
        { name: 'Valid', selector: row => row.valid, sortable: false },
        { name: 'Invalid', selector: row => row.invalid, sortable: false },
        { name: 'Inconsistent', selector: row => row.inconsistent, sortable: false },
        { name: 'Missing', selector: row => row.missing, sortable: false },
        { name: 'Data Health', selector: row => (
           
            <HealthProgressBar progress={row.dataHealth.toFixed(2)}  />
         
        ), sortable: false },
    ];

    const dataForDownloadMatching = dataMatching?.map((item, index) => {
        return {

            csvColumn: item.csvColumn,
            matchedColumn: item.matchedColumn,
            description: item.description,
            yourField: item.yourField === '-' ? <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchableSelect
                        key={index}
                        onChange={(e) => handleOnChangeYourField(e.target.value, index, e)}
                        selectedValue={item.finalColumn}
                        style={{ marginRight: '10px', cursor: 'pointer', flex: 1 }}
                        className=''
                    >
                        <optgroup label="Original Field">
                            <option selected value={item.csvColumn}>{item.csvColumn}</option>
                        </optgroup>
                        <optgroup label="Creditcurve Field">
                            <option value={item.matchedColumn}>{item.matchedColumn}</option>
                        </optgroup>
                        <optgroup label="Available GRC Fields">
                            {knownColumns.map((option, index) => (
                                <option key={index} value={option.name}>{option.name}</option>
                            ))}
                        </optgroup>
                    </SearchableSelect>
                    <img
                        src={deleteIcon}
                        onClick={(e) => deleteMatchingsItem(e, item.csvColumn)}
                        alt="delete"
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                    /> 
                    <img src={editIcon} onClick={(e)=> openCalulatedFieldModal(item)} alt="Switch" style={{width:'20px',height:'auto',marginLeft:'10px',cursor:'pointer'}} />
                    
                </div>
            </> : <>{item.yourField}<img onClick={(e)=> openCalulatedFieldModal(item)} src={editIcon} alt="Switch" style={{width:'20px',height:'auto',marginLeft:'10px',cursor:'pointer'}} /></>  ,
        

        }
    })

    const ExpandedComponent = ({ data }) => <>

    {/* <div style={{display: 'flex', alignItems: 'center'}}>
        <img src={downloadRight} alt="Arrow down right " style={{width: '80px', height: 'auto', marginRight: '20px'}} />
        <div style={{width:'100%'}}>
        <DataTable columns={columnsExpandedHealth} data={data.columns}  />
        </div>
    </div> */}

    <Modal size="lg" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
          <Modal.Title>{data.filename} - Data Health </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <DataTable columns={columnsExpandedHealth} data={data.columns}  />
        </Modal.Body>
                
    </Modal>

   

   
    
    </>;




    // const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    const columnsPaystring = [
        { name: '', selector: row => row.year, sortable: false },
        { name: 'Q1', selector: row => row.Q1, sortable: false },
        { name: 'Q2', selector: row => row.Q2, sortable: false },
        { name: 'Q3', selector: row => row.Q3, sortable: false },
        { name: 'Q4', selector: row => row.Q4, sortable: false }
    ];
    const dataPaystring = [{
        year: 2020,
        Q1: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
        Q2: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px', marginRight: '2px' }}>30</p></div>,
        Q3: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
        Q4: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
    }, {
        year: 2020,
        Q1: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
        Q2: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px', marginRight: '2px' }}>90</p></div>,
        Q3: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
        Q4: <div style={{ display: 'flex', alignItems: 'left' }}><p className='labelStatus' style={{ paddingRight: '5px', paddingLeft: '5px' }}>C</p></div>,
    }]

    const dataBorrowerAttributes = [
        { name: 'Document Type', value: 'DSCR' },
        { name: 'Original DTI', value: '0.00%' },
        { name: 'Borrower Original Fico', value: '814' },
        { name: 'Loan Purpose', value: '' },
        { name: 'All Borrower Total Income', value: '$0' },
        { name: 'Borrower Income Verification', value: '-' },
        { name: 'Updated LTV by HouseCanary', value: '52.00%' },
    ]
    const dataTerms = [
        { name: 'Origination Date', value: '2018-04-03' },
        { name: 'First Payment Date', value: '2018-04-03' },
        { name: 'Maturity Date', value: '-' },
        { name: 'Remaining Orig Term', value: '158' },
        { name: 'Loan Int Paid Through Dare', value: '2020-04-01' },
        { name: 'Loan Age', value: '22' },
    ]

    const dataPerformance = [
        { name: 'Day Delinquent', value: '0' },
        { name: 'Original Payment', value: '$2,451' },
        { name: 'Schedule Pandl', value: '$2,451' },
        { name: 'Total Principal Paid', value: '$2,451' },
        { name: 'Cumulative Interest Received', value: '$2,451' },
    ]

    const dataCollateral = [
        { name: 'Origination LTV', value: '54.19%' },
        { name: 'Current LTV', value: '54.19%' },
        { name: 'Original Combined LTV', value: '54.19%' },
        { name: 'Purchase Price', value: '$755,000' },
        { name: 'Updated Appraisal Amount', value: '$755,000' },
        { name: 'Updated Appraisal Value', value: '$755,000' },
        { name: 'Property Type', value: '2-4 Family' },
        { name: 'Current Occupancy', value: 'Investment Property' },
    ]

    const dataARM = [
        { name: 'Loan ARM Index Cashflow', value: 'DSCR' },
        { name: 'Initial Fixed Rate Period', value: '00.00%' },
        { name: 'Initial Rate Cap', value: '814' },
        { name: 'Initial Rate Floor', value: '812' },
        { name: 'Lifetime Maximum Rate', value: 'Purchase' },
        { name: 'Lifetime Minimum Rate', value: '$0' },
        { name: 'Gross Margin', value: '-' },
        { name: 'Subsequent Rate Cap', value: '52%' },
    ]

    const customWidthsPaystring = {
        '': '20%',
        'Q1': '20%',
        'Q2': '20%',
        'Q3': '20%',
        'Q4': '20%',
    };

    const columnsHistoricalPerformance = [
        { name: 'Performance', selector: row => row.performance, sortable: false },
        { name: '2019-01', selector: row => row['2019-01'], sortable: false },
        { name: '2019-02', selector: row => row['2019-02'], sortable: false },
        { name: '2019-03', selector: row => row['2019-03'], sortable: false },
        { name: '2019-04', selector: row => row['2019-04'], sortable: false },
        { name: '2019-05', selector: row => row['2019-05'], sortable: false },
        { name: '2019-06', selector: row => row['2019-06'], sortable: false },
        { name: '2019-07', selector: row => row['2019-07'], sortable: false },
    ];

    const dataHistoricalPerformace = [
        {
            'performance': 'Factor',
            '2019-01': '0.9192922',
            '2019-02': '0.9192922',
            '2019-03': '0.9192922',
            '2019-04': '0.9192922',
            '2019-05': '0.9192922',
            '2019-06': '0.9192922',
            '2019-07': '0.9192922',
        },
        {
            'performance': 'O BAL',
            '2019-01': '0.9192922',
            '2019-02': '0.9192922',
            '2019-03': '0.9192922',
            '2019-04': '0.9192922',
            '2019-05': '0.9192922',
            '2019-06': '0.9192922',
            '2019-07': '0.9192922',
        },
        {
            'performance': 'C BAL',
            '2019-01': '0.9192922',
            '2019-02': '0.9192922',
            '2019-03': '0.9192922',
            '2019-04': '0.9192922',
            '2019-05': '0.9192922',
            '2019-06': '0.9192922',
            '2019-07': '0.9192922',
        },
        {
            'performance': 'WALA',
            '2019-01': '21',
            '2019-02': '22',
            '2019-03': '23',
            '2019-04': '23',
            '2019-05': '24',
            '2019-06': '25',
            '2019-07': '26',
        },
        {
            'performance': 'CPR',
            '2019-01': '9.28%',
            '2019-02': '9.28%',
            '2019-03': '9.28%',
            '2019-04': '9.28%',
            '2019-05': '9.28%',
            '2019-06': '9.28%',
            '2019-07': '9.28%',
        },
        {
            'performance': 'CPR',
            '2019-01': '9.28%',
            '2019-02': '9.28%',
            '2019-03': '9.28%',
            '2019-04': '9.28%',
            '2019-05': '9.28%',
            '2019-06': '9.28%',
            '2019-07': '9.28%',
        }
    ]

    const columnsTranches = [
        { name: 'Period', selector: row => row.period, sortable: false },
        { name: 'Factor', selector: row => row.factor, sortable: false },
        { name: 'C Bal', selector: row => row.cBal, sortable: false },
        { name: 'CPN', selector: row => row.cpn, sortable: false },
        { name: 'Principal', selector: row => row.principal, sortable: false },
        { name: 'Interest', selector: row => row.interest, sortable: false },
        { name: 'Writedown', selector: row => row.writedown, sortable: false },
        { name: 'Shortfall', selector: row => row.shortfall, sortable: false },

    ];

    const dataTranches = [
        {
            period: 'Closing 03-19-2019',
            factor: 1.0000000,
            cBal: '$192.928',
            cpn: '',
            principal: '',
            interest: '',
            writedown: '',
            shortfall: '',
        },
        {
            period: '03-19-2019',
            factor: 0.912348966,
            cBal: '$192.928.000',
            cpn: '3.61%',
            principal: '$192.928.000',
            interest: '$192.928.000',
            writedown: '$0.0',
            shortfall: '0.00%',
        },
    ]

    // for (let i=0; i<rolesData.length; i++){
    //     dataRoles.push({11
    //         skyflow_id: rolesData[i].skyflow_id,
    //         id: i,
    //         name: <><div style={{ display: 'flex', alignItems: 'center' }}><CustomLabel variant="one" text={rolesData[i].name}/> </div></>,
    //         resource: <><Label style={{marginTop:'15px'}}>{rolesData[i].resources.replaceAll(',',', ')}</Label> </>,
    //         permission: <><div style={{ display: 'flex', alignItems: 'center' }}>{rolesData[i].permissions.includes('view') && <CustomLabel2 variant="success" text="View" />}{rolesData[i].permissions.includes('create') && <CustomLabel2 variant="info" text="Create" />}{rolesData[i].permissions.includes('edit') && <CustomLabel2 variant="warning" text="Edit" />}{rolesData[i].permissions.includes('delete') && <CustomLabel2 variant="error" text="Delete" />}</div></>,
    //         actions:<> <img alt='edit' src={editIcon} className='downloadButton' /></>
    //     })
    // }

    const handleDropdownChange = (event) => {
        // const selectedId = (event.target.value);

        // const selectedFormat = formats.find(option => option.id === selectedId);
        // setSelectedOption(selectedFormat);

        setSelectedFormat(event.target.value)

        console.log(event)
    };

    const deleteMatchingsItem = async (e,csvColumn) => {
        e.preventDefault();
        setDataMatching(prevDataMatching => {
            // Create a new array with items except the one to be removed
            return prevDataMatching.filter((item, index) => item.csvColumn !== csvColumn);
        });

       
    }

    const handleOnTabSelected = async (eventKey) => {
        if (eventKey === 'data-health') {
            getPortfolioDataHealth(dataState?.portfolioId)
        }
    }

   
   const fetchPCFFormat = async () => {
       

      try{
           const response = await  getPortfolioMatch(dataState?.portfolioId)
           console.log("PCF format response", response)
           setPCFFormat(response[0]?.dropDownMatchFields)
      }
      catch(error){
          console.log("error fetching PCF format ",error)
      }
   }

    

    const fetchMatchings = async () => {
        setLoading(true);
      

        // alert()

        try{
            const response = await getMatchings(dataState.portfolioId,selectedFormat);
            console.log("Lendable Format Response", response)
            setlendableFormat(response?.dropDownData)
            
            const mergedArray = [
                ...response.matchResponse.matched,
                ...response.matchResponse.unmatched,
                ...response.matchResponse.additional
            ];
            mergedArray?.forEach((item, index) => {

                item.finalColumn = item.yourField;
                if(item.yourField === '-'){
                    item.finalColumn = item.csvColumn;
                } 
            })
            setDataMatching(mergedArray)
            setDataDropDownMatching(response.dropDownData)
        }
        catch(error){
            console.log("error fetching matchings table ",error)
          
        }
        finally{
            setLoading(false);
        }
       
    }

    async function fetchCSVFormats() {
        const response = await getCSVMatch();

        const result = response.map(res => ({
            id: res.skyflow_id,
            formatName: res.name
        }))

        setFormats(result)
        console.log(9489384983844,formats)
        // fetchMatchings()
    }

    const saveFormat = () => {
       // alert("save format clicked")
       fetchMatchings();
    }

    const handleOnChangeYourField =  (e,index,element) => {
        // e.preventDefault();
        console.log("Selected Value",e)
        console.log("Element : ",element)
        element.target.value = e;

        setDataMatching(prevDataMatching => {
            console.log("Prev Data Matching",prevDataMatching)
            // Create a new array with items except the one to be removed
            return prevDataMatching.map((item, idx) => {
                if (idx === index) {
                    return { ...item, finalColumn: e }; // Update the item at the specified index
                }
                return item; // Return unchanged items
            });
           
        });
       
        console.log({dataMatching})
    }

    

    const downloadPortfolio = () => {
        // alert("save format clicked")
        console.log({dataMatching})
        const portfolioFormat = dataMatching.map(record => {
            return {
                csvColumn: record.csvColumn,
                description: record.description,
                matchedColumn: record.finalColumn
            }
        })
        console.log({portfolioFormat})

        console.log("Lendable Format ",dataDropDownMatching)
        let tempLendable = dataDropDownMatching;
        console.log("PCF Format",PCFFormat)

    

        tempLendable?.forEach(lendableItem => {
            PCFFormat?.forEach(pcfItem => {
                if (lendableItem.matchedColumn === pcfItem.matchedColumn) {
                    if (pcfItem.modifier) {
                        lendableItem.matchedValue = pcfItem.matchedValue;
                    }
                }
            });
        });
        
        console.log("Final Lendable Payload" , tempLendable);



       
        //  downloadPortfolioById(dataState.portfolioId, portfolioFormat, dataDropDownMatching);
        downloadPortfolioById(dataState.portfolioId, portfolioFormat, PCFFormat);
     }

     const downloadNewPortfolio = () => {
        downloadPortfolioOutput(dataState.portfolioId,selectedFormat);
        console.log("New Download HIT ")
     }


    const getPortfolioDataHealth = async (portfolioId) => {
        const portfolioHealth = await getPortfolioHealthById(portfolioId)
        console.log({portfolioHealth})
        console.log(123)
        // console.log(portfolioHealth[0])
        // console.log(456)
        // console.log(portfolioHealth[0]?.data_health)
        // console.log(789)
        // console.log(portfolioHealth[0]?.data_health?)
        if (portfolioHealth){
            const parsedData = JSON.parse(portfolioHealth[0]?.data_health)
            console.log("Parsed Data",parsedData)
            if (parsedData?.health) {
                setDataHealthFields(parsedData.health?.data)
            }
            if(parsedData?.summary){
                console.log(parsedData.summary)
                setDataSummaryFields(parsedData.summary)
            }
        }

    }

    const saveAppliedPools = () => {
        alert("save applied pools clicked")
    }

    const addPerformance = () => {
        alert("add performance clicked")
    }

    const convertToCSV = (jsonData) => {
        let overallHealth = [];
        let individualHealth = [];
        
        
        jsonData.forEach(dataItem => {
          let headers = ['valid', 'missing', 'invalid', 'inconsistent', 'dataHealth', 'filename'];
          let values = [dataItem.valid, dataItem.missing, dataItem.invalid, dataItem.inconsistent, dataItem.dataHealth, dataItem.filename];
          
          // Columns headers
          let columnHeaders = ['columnName', 'missing', 'valid', 'invalid', 'inconsistent', 'dataHealth'];
          let columnValues = dataItem.columns.map(column => (
            [column.columnName, column.missing, column.valid, column.invalid, column.inconsistent, column.dataHealth].join(',')
          ));
          
          // Merge headers and values
          overallHealth.push(headers.join(','));
          overallHealth.push(values.join(','));

          let newFile = []
          newFile.push(columnHeaders.join(','));
          newFile.push(...columnValues);

          individualHealth.push({file: newFile.join('\n'), fileName: dataItem.filename});
        });

        overallHealth = overallHealth.join('\n');

        let csvFiles = []

        csvFiles.push({file: overallHealth, fileName: 'overall_health.csv'});
    
        for (let i = 0; i < individualHealth.length; i++) {
          csvFiles.push({file: individualHealth[i].file, fileName: individualHealth[i].fileName.replace("portfolio_csv_files/","")});
        }

        return csvFiles;
      };

      const downloadCSVAsZip = (csvDataArray, filenames) => {
        const zip = new JSZip();
      
        csvDataArray.forEach((csvData, index) => {
          zip.file(filenames[index], csvData);
        });
      
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const url = window.URL.createObjectURL(content);
          const a = document.createElement('a');
          a.setAttribute('hidden', '');
          a.setAttribute('href', url);
          a.setAttribute('download', 'data-health.zip');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      };
      

      const downloadDataHealthCSV = () => {
        const csvData = convertToCSV(dataHealthFields);
        const files = csvData.map(file => file.file);
        const fileNames = csvData.map(file => file.fileName);

        downloadCSVAsZip(files, fileNames);
      }




    useEffect(() => {
        fetchPCFFormat();
        const iframe = document.getElementById('myIframe');
        iframe.onload = handleLoad;

        const tempArr = knownColumns.map((item, index) => {
            return {
                value: item.name,
                label: item.name
            }
        })
        setCreditCurveFields(tempArr)
        console.log("creditCurveFields",tempArr)
        fetchCSVFormats();
        dataState && setPortfolioId(dataState?.portfolioId)
        getPortfolioDataHealth(dataState?.portfolioId)
      

    }, [])

  


    return (
        <>
            {<LoadingSpinner show={loading} />}

            <div className="row" style={{ borderBottom: '1px solid #EAECF0', paddingBottom: '5px', marginBottom: '20px' }}>
                <div className="col-md-7">
                    <Title>Portfolio Marketplace</Title>
                </div>
                <div className="col-md-5 text-right" style={{ marginTop: '-17px' }}>
                    {/* <Button variant="default" style={{ marginRight: '10px', marginTop: '20px' }} label="Save Search">Create Portfolio</Button> */}
                    <Button variant="default" onClick={() => navigate('/dashboard/add-portfolio')} style={{ marginTop: '20px' }} label="Create Portfolio">Save Search</Button>
                </div>
            </div>

            <Title style={{ fontSize: '17px' }}>Portfolio Details</Title>


            <Tabs defaultActiveKey="data-health" id="fill-tab-example" className="mb-3 c-tabs" onSelect={handleOnTabSelected}>
                <Tab eventKey="data-health" title="Data Health" className="tab-item">
                    {/* <Title style={{ fontSize: '18px', color: '#101828',textAlign:'center' }}>Data Health</Title> */}
                    {/* <Row style={{ marginTop: '30px', marginBottom: '30px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={3} md={3} sm={3}>
                            <div>
                                <select className="form-select" onChange={(e) => handleDropdownChange(e)} style={{ marginRight: '10px' }}>
                                    <option value="">Select Format</option>
                                    {formats.map(option => (
                                        <option key={option.id} value={option.id}>{option.formatName}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={3}>
                            <Button type='submit' disabled={formats.length === 0} onClick={() => saveFormat()} label="Save Format" style={{ marginLeft: '20px' }} />
                        </Col>
                        <Col lg={3} md={3} sm={3}>
                        </Col>

                        <Col lg={3} md={3} sm={3}>
                            <TextField style={{ fontSize: '14px', lineHeight: '10px', width: '100%' }} value={searchText} onChange={(e) => { setSearchtext(e.target.value) }} placeholder="Search" type="text" />
                        </Col>


                    </Row> */}


                   
                    {/* Data Health Table  */}
                    <DataHealthTable progressPending={loading}
                    expandedRows={expandedRows}
                     onRowClicked={(e) => handleShow(e)}
                     columns={columnsDataHealth}
                     expandableRows={true}
                     expandOnRowClicked={true}
                     expandableRowsComponent={ExpandedComponent}
                     data={dataForDataHealth}
                     customWidths={customWidthsDataHealth}
                     noDataComponent={<NoDataComponent />}
                     />

                    <Button variant="default" onClick={() => downloadDataHealthCSV()} style={{ marginTop: '20px' }} label="Download Data Health">Save Search</Button>


                     {/* Data Health Table  */}

                </Tab>

                <Tab eventKey="data-summary" title="Data Summary" className="tab-item">
                   
                    {/* Data Health Table  */}
                    {/* <DataHealthTable progressPending={loading}
                    expandedRows={expandedRows}
                     onRowClicked={(e) => handleShow(e)}
                     columns={columnsDataSummary}
                     expandableRows={false}
                     expandOnRowClicked={false}
                     expandableRowsComponent={ExpandedComponent}
                     data={dataForDataSummary}
                     customWidths={customWidthsDataSummary}
                     noDataComponent={<NoDataComponent />}
                     /> */}


                    {/* <DataTable progressPending={loading} customWidths={customWidthsDataSummary} columns={columnsDataSummary} data={dataForDataSummary} /> */}
                    <Row>

                    {dataSummaryFields.map((data, index) => {
                    return (
                        <Col key={index} lg={4} md={4}>
                            <div className='connection-card'>
                                <div className="upper">
                                    <span className="card-title">{data.fileName}</span>
                                </div>

                                <div className="description" style={{ marginTop: '90px', marginLeft: '15px' }}>
                                    <Paragraph>{data.summary}</Paragraph>
                                </div>
                            </div>
                        </Col>
                    )
                })}
                    </Row>

                     {/* Data Health Table  */}

                </Tab>
{/*
                <Tab eventKey="loan-details" title="Loan Details" className="tab-item">
                    <Title style={{ fontSize: '18px', color: '#101828' }}>Loan Details</Title>
                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={4} md={4} sm={4}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>Dataset</Label>
                                <Button style={{ paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={editIcon} variant='default' label='ABCD 220-4' />
                            </div>
                        </Col>


                        <Col lg={8} md={8} sm={8} style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ marginRight: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '0' }} showIconLeft iconLeft={filtericon} variant='default' label='Filters' />
                                <Button type='submit' disabled={formats.length === 0} onClick={() => saveAppliedPools()} label="Save Applied Pools" />
                            </div>
                        </Col>

                        <hr />

                        <Col lg={12} md={12} sm={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Metrics</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>As of: 2024-01-24</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Loan Count: 154,000</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>O BAL: $1,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>C BAL: $6,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>C BAL: $1,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>WA Terms: 53</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>O FICO: 292</Label>

                            </div>
                        </Col>



                    </Row>

                    <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={3} md={3} sm={3}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginTop: '5px' }} className='section-title'>LOAN TAPE</span>
                            </div>
                        </Col>


                        <Col lg={3} md={3} sm={3} style={{ marginBottom: '0' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>As of</Label>
                                <Button style={{ paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={calenderIcon} variant='default' label='2020-04-01' />
                            </div>


                        </Col>
                        <Col lg={6} md={6} sm={6} style={{ marginBottom: '0' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>Actions</Label>
                                <Button style={{ marginRight: '10px', paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={switchVerticalIcon} variant='default' />
                                <Button style={{ marginRight: '10px', paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={tableIcon} variant='default' />
                                <Button style={{ marginRight: '10px', paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={filterFunnelIcon} variant='default' />
                                <Button style={{ marginRight: '10px', paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={messagePlusCircleIcon} variant='default' />
                                <Button style={{ marginRight: '10px', paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={downloadIcon} variant='default' />
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '1px', padding: '16px' }}>
                        <Col lg={2} md={2} sm={2} style={{ borderRight: '1px solid #ccc' }}>
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: "16px", paddingBottom: "16px" }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>Loan ID</Label>
                            </div>
                        </Col>

                        <Col lg={10} md={10} sm={10}>
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: "16px", paddingBottom: "16px" }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>Residential Mortgage</Label>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', borderRadius: '12px', paddingLeft: '16px', paddingRight: '16px' }}>
                        <Col lg={2} md={2} sm={2} style={{ paddingLeft: '0', paddingRight: '0' }}>
                            <div style={{ marginTop: '16px', marginBottom: '16px', display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <input name="dealFlow" checked={true} onChange={(e) => console.log(e)} type='checkbox' className='custom-checkbox' style={{ marginLeft: '10px' }} />
                                <Label style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '0' }}>202050000</Label>
                                <div style={{ position: 'absolute', top: -16, bottom: -16, right: '-1px', width: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                                <div style={{ position: 'absolute', bottom: -16, right: 0, width: '100%', height: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                            </div>
                            <div style={{ marginTop: '32px', marginBottom: '16px', display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <input name="dealFlow" checked={false} onChange={(e) => console.log(e)} type='checkbox' className='custom-checkbox' style={{ marginLeft: '10px' }} />
                                <Label style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '0' }}>202050000</Label>
                                <div style={{ position: 'absolute', top: -16, bottom: -16, right: '-1px', width: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                                <div style={{ position: 'absolute', bottom: -16, right: 0, width: '100%', height: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                            </div>
                            <div style={{ marginTop: '32px', marginBottom: '16px', display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <input name="dealFlow" checked={false} onChange={(e) => console.log(e)} type='checkbox' className='custom-checkbox' style={{ marginLeft: '10px' }} />
                                <Label style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '0' }}>202050000</Label>
                                <div style={{ position: 'absolute', top: -16, bottom: -16, right: '-1px', width: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                                <div style={{ position: 'absolute', bottom: -16, right: 0, width: '100%', height: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                            </div>
                            <div style={{ marginTop: '32px', marginBottom: '16px', display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <input name="dealFlow" checked={false} onChange={(e) => console.log(e)} type='checkbox' className='custom-checkbox' style={{ marginLeft: '10px' }} />
                                <Label style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '0' }}>202050000</Label>
                                <div style={{ position: 'absolute', top: -16, bottom: -16, right: '-1px', width: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                                <div style={{ position: 'absolute', bottom: -16, right: 0, width: '100%', height: '1px', backgroundColor: '#ccc', zIndex: 1 }}></div>
                            </div>
                        </Col>

                        <Col lg={10} md={10} sm={10} >
                            <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '20px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                <Col lg={12} md={12} sm={12}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Loan ID:</Label>
                                                    <Label style={{ color: '#344054' }}>20050000</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Original LTV:</Label>
                                                    <Label style={{ color: '#344054' }}>60.00%</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Loan Coupon:</Label>
                                                    <Label style={{ color: '#344054' }}>4.00%</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Loan Purpose:</Label>
                                                    <Label style={{ color: '#344054' }}>Purchase</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Loan Status:</Label>
                                                    <Label style={{ color: '#344054' }}>Current</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center', marginRight: '50px' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Property City:</Label>
                                                    <Label style={{ color: '#344054' }}>New York</Label>
                                                </div>
                                            </div>
                                            <div style={{ padding: '16px 0', textAlign: 'center' }}>
                                                <div>
                                                    <Label style={{ color: '#475467' }}>Property State:</Label>
                                                    <Label style={{ color: '#344054' }}>NY</Label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>





                            </Row>

                            <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '20px', padding: '0px' }}>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>PAYSTRING</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', borderRadius: '0px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <DataTable columns={columnsPaystring} data={dataPaystring} customWidths={customWidthsPaystring} />
                                        </Col>
                                    </Row>

                                </Col>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>BORROWER ATTRIBUTES</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', margin: '0px', borderRadius: '12px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col lg={12} md={12} sm={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                            {dataBorrowerAttributes.map((item, index) => (
                                                <div key={index} style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', alignItems: 'center', outline: 'none', borderBottom: '1px solid #ccc', borderRadius: '4px' }}>
                                                    <Label style={{ paddingLeft: '20px', marginBottom: '0', color: '#101828', marginRight: 'auto' }}>{item.name}</Label>
                                                    <Label style={{ paddingRight: '20px', marginBottom: '0', color: '#475467', marginLeft: 'auto' }}>{item.value}</Label>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>



                            </Row>

                            <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '20px', padding: '0px' }}>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>TERMS</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', margin: '0px', borderRadius: '12px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col lg={12} md={12} sm={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                            {dataTerms.map((item, index) => (
                                                <div key={index} style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', alignItems: 'center', outline: 'none', borderBottom: '1px solid #ccc', borderRadius: '4px' }}>
                                                    <Label style={{ paddingLeft: '20px', marginBottom: '0', color: '#101828', marginRight: 'auto' }}>{item.name}</Label>
                                                    <Label style={{ paddingRight: '20px', marginBottom: '0', color: '#475467', marginLeft: 'auto' }}>{item.value}</Label>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>PERFORMANCE</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', margin: '0px', borderRadius: '12px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col lg={12} md={12} sm={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                            {dataPerformance.map((item, index) => (
                                                <div key={index} style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', alignItems: 'center', outline: 'none', borderBottom: '1px solid #ccc', borderRadius: '4px' }}>
                                                    <Label style={{ paddingLeft: '20px', marginBottom: '0', color: '#101828', marginRight: 'auto' }}>{item.name}</Label>
                                                    <Label style={{ paddingRight: '20px', marginBottom: '0', color: '#475467', marginLeft: 'auto' }}>{item.value}</Label>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>



                            </Row>

                            <Row style={{ marginTop: '30px', marginBottom: '60px', marginRight: '0px', marginLeft: '20px', padding: '0px' }}>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>COLLATERAL</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', margin: '0px', borderRadius: '12px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col lg={12} md={12} sm={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                            {dataCollateral.map((item, index) => (
                                                <div key={index} style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', alignItems: 'center', outline: 'none', borderBottom: '1px solid #ccc', borderRadius: '4px' }}>
                                                    <Label style={{ paddingLeft: '20px', marginBottom: '0', color: '#101828', marginRight: 'auto' }}>{item.name}</Label>
                                                    <Label style={{ paddingRight: '20px', marginBottom: '0', color: '#475467', marginLeft: 'auto' }}>{item.value}</Label>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>

                                <Col lg={6} md={6} sm={6} >
                                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                                        <Col lg={12} md={12} sm={12}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginTop: '5px' }} className='section-title'>ARM</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px', margin: '0px', borderRadius: '12px', paddingLeft: '0px', paddingRight: '0px' }}>
                                        <Col lg={12} md={12} sm={12} style={{ paddingLeft: '0', paddingRight: '0' }}>
                                            {dataARM.map((item, index) => (
                                                <div key={index} style={{ paddingTop: '30px', paddingBottom: '30px', display: 'flex', alignItems: 'center', outline: 'none', borderBottom: '1px solid #ccc', borderRadius: '4px' }}>
                                                    <Label style={{ paddingLeft: '20px', marginBottom: '0', color: '#101828', marginRight: 'auto' }}>{item.name}</Label>
                                                    <Label style={{ paddingRight: '20px', marginBottom: '0', color: '#475467', marginLeft: 'auto' }}>{item.value}</Label>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col>


                        </Col>
                    </Row>









                </Tab>

                <Tab eventKey="performance" title="Performance" className="tab-item">
                    <Title style={{ fontSize: '18px', color: '#101828' }}>Performance</Title>
                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={12} md={12} sm={12} style={{ marginBottom: '30px' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Historical Performance</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Loan Age Analysis</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Historical Analysis</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Deal Age Analysis</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Transition Matrix</Label>
                            </div>
                        </Col>

                        <Col lg={4} md={4} sm={4}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Label style={{ marginRight: '10px', marginBottom: '0' }}>Dataset</Label>
                                <Button style={{ paddingLeft: '17px', paddingRight: '17px', marginBottom: '0' }} showIconLeft iconLeft={editIcon} variant='default' label='ABCD 220-4' />
                            </div>
                        </Col>


                        <Col lg={8} md={8} sm={8} style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ marginRight: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '0' }} showIconLeft iconLeft={filtericon} variant='default' label='Filters' />
                                <Button type='submit' disabled={formats.length === 0} onClick={() => addPerformance()} label="Add" />
                            </div>
                        </Col>

                        <hr />

                        <Col lg={12} md={12} sm={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>As of: 2024-01-24</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>Loan Count: 154,000</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>O BAL: $1,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>C BAL: $6,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>C BAL: $1,534</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>WA Terms: 53</Label>
                                <Label style={{ marginRight: '30px', marginBottom: '0', color: '#667085' }}>O FICO: 292</Label>

                            </div>
                        </Col>



                    </Row>

                    <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={12} md={12} sm={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginTop: '5px' }} className='section-title'>HISTORICAL PERFORMANCE</span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', borderRadius: '0px' }}>
                        <Col lg={12} md={12} sm={12}>
                            <DataTable progressPending={loading} columns={columnsHistoricalPerformance} data={dataHistoricalPerformace} />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '30px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', backgroundColor: '#f0f2f3', borderRadius: '12px', padding: '16px' }}>
                        <Col lg={12} md={12} sm={12}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginTop: '5px' }} className='section-title'>TRANCHES</span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '0px', marginBottom: '0px', marginRight: '0px', marginLeft: '0px', borderRadius: '0px' }}>
                        <Col lg={12} md={12} sm={12}>
                            <DataTable progressPending={loading} columns={columnsTranches} data={dataTranches} />
                        </Col>
                    </Row>




                </Tab>
*/}
                <Tab eventKey="matching" title="Matching" className="tab-item">
                    <Title style={{ fontSize: '18px', color: '#101828' }}>Matching</Title>
                    {/* <Formats/> */}
                    <hr/>
                    <Row>
                    <Col lg={3} md={3} sm={3}>
                            <div>
                                <select className="form-select" onChange={(e) => handleDropdownChange(e)} style={{ marginRight: '10px' }}>
                                    <option value="">Select Format</option>
                                    {formats.map(option => (
                                        <option key={option.id} value={option.id}>{option.formatName}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={3}>
                            <Button type='submit' disabled={formats.length === 0} onClick={() => saveFormat()} label="Apply Format " style={{ marginLeft: '20px' }} />
                        </Col>

                        <Col lg={3} md={3} sm={3}>
                            <Button type='submit' disabled={formats.length === 0} onClick={() => downloadPortfolio()} label="Download " style={{ marginLeft: '20px' }} />
                        </Col>

                        <Col lg={3} md={3} sm={3}>
                            <Button type='submit' disabled={formats.length === 0} onClick={() => downloadNewPortfolio()} label="Download New  " style={{ marginLeft: '20px' }} />
                        </Col>

                        <Col lg={3} md={3} sm={3}>
                        </Col>


                        {/* <Col lg={3} md={3} sm={3}>
                            <Button variant="default" disabled={formats.length === 0} onClick={() => saveFormat()} label="Update " style={{ marginLeft: '20px',float:'right' }} />
                        </Col> */}
                        </Row>
                        <hr/>

                    <DataTable progressPending={loading} customWidths={customWidthsDataMatching} columns={columnsMatching} data={dataForDownloadMatching} />
                </Tab>
                


            
                <Tab eventKey="chat-tool" title="Chat Tool" className="tab-item">
                    
                   
                   <div style={{width:'100%',height:'auto',border:'none',padding:'0px',borderRadius:'0px'}}>


                    {/*  */}

                    <div style={{ position: 'relative', width: '100%', height: '100%',overflow:'hidden' }}>
      {iframeLoading && (
        <div style={{ 
          position: 'absolute', 
          top: '10%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          zIndex: 1,
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
          Loading CreditCurve AI Chat Tool ...
        </div>
      )}
      <iframe
        id="myIframe"
        // src={`https://chatapi.creditcurve.ai/?id=${dataState.portfolioId}`}
        // src={`http://52.152.140.177:8080/?id=${dataState.portfolioId}`}
        src={`http://52.152.140.177:8080/?id=${dataState.portfolioId}`}
        
        style={{
          width: '100%',
          height: '605px',
          border: 'none',
          display: loading ? 'none' : 'block',
          overflow:'hidden'
        }}
      ></iframe>
    </div>

                    {/*  */}

                   </div>
                   


                </Tab>

                {/* <Tab eventKey="pivot-table" title="Pivot Table" className="tab-item">
                 </Tab> */}
            </Tabs>


            <Modal size="lg" show={calculatedFieldModal} onHide={()=>setCalculatedFieldModal(false)}>
    <Modal.Header closeButton>
          <Modal.Title>Custom Calculated Field  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                    <Pivot />
        </Modal.Body>
                
 </Modal>

        </>
    )
}
export default ListingDetails