import React, { useCallback, useState, useEffect } from 'react';
import { ReactFlow, ReactFlowProvider, addEdge, useNodesState, useEdgesState, Background, Controls, MiniMap, Handle } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import knownColumns from '../../../../utils/knownColumns.json';
import { Badge } from 'react-bootstrap';

const CustomNode = ({ id, data, isConnectable }) => {
  if (['aggregation', 'association'].includes(data.label.toLowerCase())) {
    const isAggregation = data.label.toLowerCase() === 'aggregation';
    return (
      <div className={`custom-node ${data.label.toLowerCase()}`}>
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div className="fields-container">
          <div 
            className="field filter-field" 
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => data.onFieldDrop(event, id, 'field1')}
          >
            {data.field1 ? data.field1.fieldName : 'Filter Field'}
          </div>
          <div 
            className={`field ${isAggregation ? 'aggregate-field' : 'associate-field'}`}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => data.onFieldDrop(event, id, 'field2')}
          >
            {data.field2 ? data.field2.fieldName : (isAggregation ? 'Aggregate Field' : 'Associate Field')}
          </div>
        </div>
        {id !== 'customField' && (
          <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        )}
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'date_formatting') {
    return (
      <div className="custom-node date-formatting">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div 
          className="field" 
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => data.onFieldDrop(event, id, 'field')}
        >
          {data.field ? data.field.fieldName : 'Drop field here'}
        </div>
        <select 
          value={data.dateFormat || 'DD/MM/YYYY'} 
          onChange={(e) => data.onDateFormatChange(id, e.target.value)}
        >
        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
        <option value="DD-MM-YYYY">DD-MM-YYYY</option>
        <option value="MM/DD/YYYY">MM/DD/YYYY</option>
        <option value="MM-DD-YYYY">MM-DD-YYYY</option>
        <option value="YYYY-MM-DD">YYYY-MM-DD</option>
        <option value="YYYY-MM-DD HH:MM:SS">YYYY-MM-DD HH:MM:SS</option>
        </select>
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'concatenation') {
    return (
      <div className="custom-node concatenation">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div className="fields-container">
          {data.concatInputs.map((input, index) => (
            <div key={index}>
              <select 
                value={input.type || 'selected'} 
                onChange={(e) => data.onConcatInputTypeChange(id, index, e.target.value)}
                
              >
               <option value='selected'> - - Select Type - -</option>
                <option value="field">Add Field</option>
                <option value="custom">Add Custom Value</option>
              
               
              </select>
  
              {input.type === 'field' && (
                <div 
                  className="field concat-field" 
                  onDragOver={(event) => event.preventDefault()}
                  onDrop={(event) => data.onConcatFieldDrop(event, id, index)}
                >
                  {input.field ? input.field.fieldName : 'Drop field here'}
                </div>
              )}
  
              {input.type === 'custom' && (
                <input
                  type="text"
                  placeholder="Enter custom value"
                  value={input.value || ''}
                  onChange={(e) => data.onConcatCustomValueChange(id, index, e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
        <button onClick={() => data.onAddConcatInput(id)}>Add +</button>
        <input
          type="text"
          placeholder="Separator symbol"
          value={data.separator || ''}
          onChange={(e) => data.onConcatSeparatorChange(id, e.target.value)}
        />
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'logical_comparison') {
    return (
      <div className="custom-node logical-comparison">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        {data.conditions.map((condition, index) => (
          <div key={index} className="condition-group">
            <select
              value={condition.comparison || 'isEquals'}
              onChange={(e) => data.onComparisonChange(id, index, e.target.value)}
            >
              <option value="isEquals">isEquals</option>
              <option value="isNotEqual">isNotEqual</option>
              <option value="isGreaterThan">isGreaterThan</option>
              <option value="isLessThan">isLessThan</option>
              <option value="isNotNull">isNotNull</option>
              <option value="isNull">isNull</option>
              <option value="isEmpty">isEmpty</option>
              <option value="isTrue">isTrue</option>
              <option value="isFalse">isFalse</option>
            </select>
            {['isEquals', 'isNotEqual', 'isGreaterThan', 'isLessThan'].includes(condition.comparison) && (
              <input
                type="text"
                placeholder="Compare value"
                value={condition.compareValue || ''}
                onChange={(e) => data.onCompareValueChange(id, index, e.target.value)}
              />
            )}
            <input
              type="text"
              placeholder={`Value for ${condition.label || 'IF'} condition`}
              value={condition.value || ''}
              onChange={(e) => data.onConditionValueChange(id, index, e.target.value)}
            />
          </div>
        ))}
        <button onClick={() => data.onAddCondition(id)}>Add Else If</button>
        {data.conditions.length > 0 && (
          <div className="condition-group">
            <select value="else" disabled>
              <option value="else">Else</option>
            </select>
            <input
              type="text"
              placeholder="Else value"
              value={data.elseValue || ''}
              onChange={(e) => data.onElseValueChange(id, e.target.value)}
            />
          </div>
        )}
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (['custom_value', 'rand_id_generator', 'rename', 'direct_match'].includes(data.label.toLowerCase())) {
    return (
      <div className={`custom-node ${data.label.toLowerCase()}`}>
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        {data.label.toLowerCase() === 'custom_value' && (
          <input
            type="text"
            placeholder="Enter custom value"
            value={data.customValue || ''}
            onChange={(e) => data.onCustomValueChange(id, e.target.value)}
          />
        )}
        {data.label.toLowerCase() === 'rename' && (
          <>
            <input
              type="text"
              placeholder="Enter first value"
              value={data.renameValue1 || ''}
              onChange={(e) => data.onRenameValueChange(id, 'renameValue1', e.target.value)}
            />
            <input
              type="text"
              placeholder="Enter second value"
              value={data.renameValue2 || ''}
              onChange={(e) => data.onRenameValueChange(id, 'renameValue2', e.target.value)}
            />
          </>
        )}
        {data.label.toLowerCase() === 'direct_match' && (
          <div 
            className="direct-match-field" 
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => data.onFieldDrop(event, id, 'field')}
          >
            {data.field ? data.field.fieldName : 'Drop field'}
          </div>
        )}
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  return (
    <div className={`custom-node ${data.isOperation ? 'operation' : 'field'} ${id==='customField' ? 'final_field' :''}`}>
      <Handle type="target" position="top" isConnectable={isConnectable} />
      <div>{data.label}</div>
      {id !== 'customField' && (
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
      )}
      <Handle type="source" position="bottom" isConnectable={isConnectable} />
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const FlowComponent = ({isFormat, customFieldName, fields, operations, savedFlow, onSave, itemIndex }) => {
  const initialNodes = [
    {
      id: 'customField',
      type: 'custom',
      position: { x: 250, y: 250 },
      data: { label: customFieldName, isOperation: false },
      style: { backgroundColor: 'green', color: 'white' },
    },
  ];
  const initialEdges = [];
  
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [searchTerm, setSearchTerm] = useState('');
  const [rfInstance, setRfInstance] = useState(null);

  useEffect(() => {
    if (savedFlow) {
      const flow = JSON.parse(savedFlow);
      if (flow.nodes) {
        setNodes(flow.nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            onDelete: onDeleteNode,
            onFieldDrop: onFieldDrop,
            onCustomValueChange: onCustomValueChange,
            onRenameValueChange: onRenameValueChange,
            onComparisonChange: onComparisonChange,
            onDateFormatChange: onDateFormatChange,
            onSeparatorChange: onSeparatorChange,
            onConcatTypeChange: onConcatTypeChange,
            onCompareValueChange: onCompareValueChange,
            // onTrueCaseChange: onTrueCaseChange,
            // onFalseCaseChange: onFalseCaseChange,
            onAddCondition: onAddCondition,
            onConditionValueChange: onConditionValueChange,
            onElseValueChange: onElseValueChange,
          }
        })));
      }
      if (flow.edges) {
        setEdges(flow.edges);
      }
    }
  }, [savedFlow]);

  const isOperation = (type) => ['addition', 'subtraction', 'multiplication', 'average', 'aggregation', 'association', 'custom_value', 'rand_id_generator', 'rename', 'logical_comparison', 'direct_match', 'date_formatting', 'concatenation'].includes(type.toLowerCase());
  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds));
  }, [setEdges]);

  const onDragStart = (event, field) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(field));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const generateUniqueId = () => `operation_${new Date().getTime()}`;

  const onFieldDrop = useCallback((event, nodeId, fieldKey) => {
    event.preventDefault();
    const fieldData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
  
    const isOperation = fieldData.fileName === 'operation';
    const operationId = isOperation ? generateUniqueId() : null; // Assign unique ID if it's an operation
  
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const updatedData = {
            ...node.data,
            [fieldKey]: {
              ...fieldData,
              isOperation, // Flag to indicate whether it's an operation
              operationId // Store the unique operation ID
            }
          };
          return {
            ...node,
            data: updatedData,
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onCustomValueChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              customValue: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onRenameValueChange = useCallback((nodeId, key, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              [key]: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onComparisonChange = useCallback((nodeId, index, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConditions = [...node.data.conditions];
          newConditions[index].comparison = value;
          return {
            ...node,
            data: {
              ...node.data,
              conditions: newConditions,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onDateFormatChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              dateFormat: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onSeparatorChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              separator: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onConcatTypeChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              concatType: value,
              customValue: value === 'custom' ? '' : node.data.customValue,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onCompareValueChange = useCallback((nodeId, index, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConditions = [...node.data.conditions];
          newConditions[index].compareValue = value;
          return {
            ...node,
            data: {
              ...node.data,
              conditions: newConditions,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onConditionValueChange = useCallback((nodeId, index, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConditions = [...node.data.conditions];
          newConditions[index].value = value;
          return {
            ...node,
            data: {
              ...node.data,
              conditions: newConditions,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onElseValueChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              elseValue: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  const onConcatInputTypeChange = useCallback((nodeId, index, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConcatInputs = [...node.data.concatInputs];
          newConcatInputs[index].type = value;
          if (value === 'custom') {
            newConcatInputs[index].value = '';
          } else if (value === 'field') {
            newConcatInputs[index].field = null;
          }
          return {
            ...node,
            data: {
              ...node.data,
              concatInputs: newConcatInputs,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onConcatCustomValueChange = useCallback((nodeId, index, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConcatInputs = [...node.data.concatInputs];
          newConcatInputs[index].value = value;
          return {
            ...node,
            data: {
              ...node.data,
              concatInputs: newConcatInputs,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onConcatFieldDrop = useCallback((event, nodeId, index) => {
    event.preventDefault();
    const fieldData = JSON.parse(event.dataTransfer.getData('application/reactflow'));
  
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConcatInputs = [...node.data.concatInputs];
          newConcatInputs[index].field = fieldData;
          return {
            ...node,
            data: {
              ...node.data,
              concatInputs: newConcatInputs,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onAddConcatInput = useCallback((nodeId) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConcatInputs = [...node.data.concatInputs, { type: 'select' }];
          return {
            ...node,
            data: {
              ...node.data,
              concatInputs: newConcatInputs,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onConcatSeparatorChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              separator: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);
  
  const onAddCondition = useCallback((nodeId) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          const newConditions = [...node.data.conditions];
          newConditions.push({
            comparison: 'isEquals',
            value: '',
          });
          return {
            ...node,
            data: {
              ...node.data,
              conditions: newConditions,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onDrop = useCallback((event) => {
    event.preventDefault();
  
    const reactFlowBounds = event.target.getBoundingClientRect();
    const type = JSON.parse(event.dataTransfer.getData('application/reactflow'));
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };
  
    // Check if the drop target is an operation node
    const targetNode = nodes.find(node => 
      node.position.x < position.x && node.position.x + node.width > position.x &&
      node.position.y < position.y && node.position.y + node.height > position.y &&
      node.data.isOperation
    );
  
    if (targetNode && !isOperation(type.fieldName)) {
      // If dropping a field on an operation node, update its fields instead of creating a new node
      const fieldKey = targetNode.data.field1 ? 'field2' : 'field1';
      onFieldDrop(event, targetNode.id, fieldKey);
    } else if (type.fieldName.toLowerCase() === 'concatenation') {
      // If the dropped node is the concatenation operation, initialize it with specific handlers
      const newNode = {
        id: `${type.fieldName}_${new Date().getTime()}`,
        type: 'custom',
        position,
        data: { 
          label: type.fieldName,
          isOperation: true, // Concatenation is an operation
          onDelete: onDeleteNode,
          onConcatInputTypeChange: onConcatInputTypeChange, // Custom handler for concatenation
          onConcatCustomValueChange: onConcatCustomValueChange,
          onConcatFieldDrop: onConcatFieldDrop,
          onAddConcatInput: onAddConcatInput,
          onConcatSeparatorChange: onConcatSeparatorChange,
          concatInputs: [], // Start with an empty array for dynamic inputs
          separator: '', // Start with an empty separator input
        },
        style: { backgroundColor: 'lightblue' },
      };
  
      setNodes((nds) => nds.concat(newNode));
    } else if (isOperation(type.fieldName) || !targetNode) {
      // Create a new node for operations or fields dropped outside of operations
      const newNode = {
        id: `${type.fieldName}_${new Date().getTime()}`,
        type: 'custom',
        position,
        data: { 
          label: type.fieldName,
          isOperation: isOperation(type.fieldName),
          onDelete: onDeleteNode,
          onFieldDrop: onFieldDrop,
          onCustomValueChange: onCustomValueChange,
          onRenameValueChange: onRenameValueChange,
          onComparisonChange: onComparisonChange,
          onDateFormatChange: onDateFormatChange,
          onSeparatorChange: onSeparatorChange,
          onConcatTypeChange: onConcatTypeChange,
          onCompareValueChange: onCompareValueChange,
          onAddCondition: onAddCondition,
          onConditionValueChange: onConditionValueChange,
          onElseValueChange: onElseValueChange,
          fileName: type.fileName,
          fields: type.fieldName.toLowerCase() === 'concatenation' ? [] : undefined,
          conditions: type.fieldName.toLowerCase() === 'logical_comparison' ? [{ comparison: 'isEquals', value: '' }] : undefined,
        },
        style: isOperation(type.fieldName) ? { backgroundColor: 'lightblue' } : {},
      };
  
      setNodes((nds) => nds.concat(newNode));
    }
  }, [nodes, setNodes, onFieldDrop, onCustomValueChange, onRenameValueChange, onComparisonChange, onDateFormatChange, onSeparatorChange, onConcatTypeChange, onCompareValueChange, onAddCondition, onConditionValueChange, onElseValueChange, onConcatInputTypeChange, onConcatCustomValueChange, onConcatFieldDrop, onAddConcatInput, onConcatSeparatorChange, isOperation]);
  

  
  const onDeleteNode = useCallback((id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
  }, [setNodes, setEdges]);

  const onInit = useCallback((reactFlowInstance) => {
    setRfInstance(reactFlowInstance);
    reactFlowInstance.fitView({ padding: 0.2, maxZoom: 0.90 });
  }, []);

  const generatePayload = useCallback(() => {
    const payload = {
      resultField: customFieldName,
      operations: []
    };
  
    const operationIndices = new Map();
  
    const processNode = (nodeId, visitedNodes = new Set()) => {
      const node = nodes.find(n => n.id === nodeId);
  
      if (!node) {
        console.error(`Node with ID ${nodeId} not found.`);
        return;
      }
  
      // If the node has already been processed, return its index
      if (visitedNodes.has(nodeId)) return operationIndices.get(nodeId);
      visitedNodes.add(nodeId);
  
      const incomingEdges = edges.filter(e => e.target === nodeId);
  
      if (node.data.isOperation) {
        const operation = {
          type: node.data.label.toLowerCase(),
          inputs: []
        };
  
        // Handle different operation types
        switch (node.data.label.toLowerCase()) {
          case 'aggregation':
          case 'association':
            if (node.data.field1) operation.inputs.push({ type: "field", fieldName: node.data.field1.fieldName, filename: node.data.field1.fileName });
            if (node.data.field2) operation.inputs.push({ type: "field", fieldName: node.data.field2.fieldName, filename: node.data.field2.fileName });
            break;
          case 'custom_value':
            operation.inputs.push({ type: "value", value: node.data.customValue || '' });
            break;
          case 'rand_id_generator':
            break;
          case 'rename':
            operation.inputs.push({ type: "value", value: node.data.renameValue1 || '' });
            operation.inputs.push({ type: "value", value: node.data.renameValue2 || '' });
            break;
          case 'logical_comparison':
            // Process connected field or operation and add it to the inputs
            if (incomingEdges.length > 0) {
              const sourceNode = nodes.find(n => n.id === incomingEdges[0].source);
              if (sourceNode.data.isOperation) {
                const operationIndex = processNode(sourceNode.id, visitedNodes);
                if (operationIndex !== undefined) {
                  operation.inputs.push({ type: "operation", value: `operation: ${operationIndex}` });
                }
              } else {
                operation.inputs.push({ type: "field", fieldName: sourceNode.data.label, filename: sourceNode.data.fileName || '' });
              }
            }
            
            node.data.conditions.forEach(condition => {
              operation.inputs.push({ type: "comparison", comparison: condition.comparison, value: condition.value, compareValue: condition.compareValue });
            });
            operation.inputs.push({ type: "else", value: node.data.elseValue || '' });
            break;
          case 'direct_match':
            if (node.data.field) operation.inputs.push({ type: "field", fieldName: node.data.field.fieldName, filename: node.data.field.fileName });
            break;
          case 'date_formatting':
            if (node.data.field) {
              operation.inputs.push({ type: "field", fieldName: node.data.field.fieldName, filename: node.data.field.fileName });
            }
            operation.inputs.push({ type: "value", value: node.data.dateFormat || '1' });
            break;
            case 'concatenation':
              node.data.concatInputs.forEach((input) => {
                if (input.type === 'field') {
                  operation.inputs.push({ type: "field", fieldName: input.field.fieldName, filename: input.field.fileName });
                } else if (input.type === 'custom') {
                  operation.inputs.push({ type: "value", value: input.value });
                }
              });
              operation.inputs.push({ type: "value", value: node.data.separator || '' }); // Add separator as the last input
              break;
            
          default:
            for (const edge of incomingEdges) {
              const input = processNode(edge.source, visitedNodes);
              if (input !== undefined) {
                if (typeof input === 'number') {
                  operation.inputs.push({ type: "operation", value: `operation: ${input}` });
                } else {
                  const sourceNode = nodes.find(n => n.id === edge.source);
                  operation.inputs.push({ type: "field", fieldName: input, filename: sourceNode?.data?.fileName || '' });
                }
              }
            }
        }
  
        const operationIndex = payload.operations.length;
        operationIndices.set(nodeId, operationIndex);
        payload.operations.push(operation);
        return operationIndex;
      } else {
        return node.data.label;
      }
    };
  
    // Process nodes connected to the result field
    const resultNodeEdges = edges.filter(e => e.target === 'customField');
    for (const edge of resultNodeEdges) {
      processNode(edge.source);
    }
  
    const flow = rfInstance.toObject();
    console.log('Payload:', JSON.stringify(payload, null, 2));
    onSave(payload, flow, itemIndex);
  }, [nodes, edges, customFieldName, rfInstance, onSave, itemIndex]);
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFields = fields.filter(field => field.fieldName.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <div className="flow-container">
          <aside>
            <div className="section">
              <h4>Input Fields</h4>
              <input
                type="text"
                placeholder="Search fields"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              <div className="scrollable">
                {filteredFields.map((field, index) => (
                  <div key={index} className="dndnode field" onDragStart={(event) => onDragStart(event, field)} draggable>
                    {field.fieldName}<br/>
                    {!isFormat && (<Badge variant="primary">{field.fileName}</Badge> )}
                  </div>
                ))}
              </div>
            </div>
            <div className="section">
              <h4>Operations</h4>
              <div className="scrollable">
                {operations.map((operation, index) => (
                  <div key={index} className="dndnode operation" onDragStart={(event) => onDragStart(event, { fieldName: operation, fileName: 'operation' })} draggable>
                    {operation}
                  </div>
                ))}
              </div>
            </div>
          </aside>
          <div className="reactflow-wrapper">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onInit={onInit}
              nodeTypes={nodeTypes}
              fitView
            >
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </div>
        <div className="button-container">
          <button onClick={generatePayload}>Confirm</button>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

const App = ({csvColumn = '', onSave, itemIndex, savedFlow = null, isFormat=true, userFields = null}) => {
  const fields = isFormat 
    ? knownColumns.map((column) => ({ fieldName: column.name, fileName: "GRC_FIELD" })) 
    : userFields;
  const operations = [
    'addition', 
    'subtraction', 
    'multiplication', 
    'average', 
    'aggregation', 
    'association', 
    'custom_value', 
    'rand_id_generator', 
    'rename', 
    'logical_comparison', 
    'direct_match',
    'date_formatting',
    'concatenation'
  ];

  return <FlowComponent isFormat={isFormat} onSave={onSave} itemIndex={itemIndex} customFieldName={csvColumn} fields={fields} operations={operations} savedFlow={savedFlow} />;
};

// Styles
const styles = `
.dndflow {
  display: flex;
  flex-direction: column;
  height: 78vh;
  border:2px solid grey;
  padding: 10px;
  border-radius: 15px;
}

.flow-container {
  display: flex;
  flex: 1;
}

aside {
  width: 300px;
  padding: 10px;
  border-right: 1px solid #ddd;
  background: #f7f7f7;
  overflow: auto;
}

.reactflow-wrapper {
  flex: 1;
  height: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
}

.button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #7F56D9;
  color: white;
  border: none;
  font-weight:bold;
  border-radius: 5px;
  cursor: pointer;
  width:200px;
  font-size: 14px;
}

.button-container button:hover {
  background-color: #442981;
  transition: background-color 0.3s;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.scrollable {
  max-height: 250px;
  overflow-y: auto;
}

.dndnode {
  padding: 5px;
  margin-bottom: 5px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: grab;
}

.dndnode.field {
  border-radius: 5px;
}

.dndnode.operation {
  border-radius: 0%;
  background: lightblue;
}

.custom-node {
  padding: 5px;
  background-color: lightblue;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  align-items: center;
}

.custom-node.aggregation,
.custom-node.association,
.custom-node.custom-value,
.custom-node.rand-id-generator,
.custom-node.rename,
.custom-node.logical-comparison,
.custom-node.direct-match,
.custom-node.date-formatting,
.custom-node.concatenation {
  width: 200px;
}

.fields-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.field {
  border: 1px dashed #888;
  padding: 5px;
  margin: 5px 0;
  text-align: center;
}

.filter-field {
  background-color: #ffff99;
}

.aggregate-field {
  background-color: #90EE90;
}

.associate-field {
  background-color: #ADD8E6;
}

.concat-field {
  background-color: #FFA07A;
}

.custom-node input, 
.custom-node select {
  width: 90%;
  margin: 5px 0;
  padding: 3px;
}

.input-label {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.delete-button:hover {
  background-color: #ff0000;
}

.final_field {
  background-color: green;
  color: white;
}
`;

// Inject the CSS into the document
const styleElement = document.createElement('style');
styleElement.textContent = styles;
document.head.appendChild(styleElement);

export default App;
