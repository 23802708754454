import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './style.css';
import Label from '../../Typorgraphy/Label';
import TextField from '../../TextField';
import plusIcon from '../../../assets/icons/plus.svg'
import AnchorText from '../../Typorgraphy/AnchorText'
import cardHeading from '../../../assets/icons/cardheading.svg'
import Modal from '../../Modal';
import DataTable from '../../DataTable/index'
import deleteIcon from '../../../assets/icons/delete.svg'
import copyIcon from '../../../assets/icons/copy.svg'
import { SketchPicker } from 'react-color';
import { getDealPageSettings, deleteDealPageSettings, createDealPageSettings } from '../../../api/settings';

const columns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Theme',
        selector: row => row.theme,
        sortable: true,
    },
    {
        name: 'Link',
        selector: row => row.link,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        sortable: false,
    }

];

const data = [
    {
        id: 1,
        name: <Label style={{ marginTop: '15px' }}>Lendable</Label>,
        theme: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: '#86eb34',
                        marginRight: '8px',
                    }}
                />
                <span>#86eb34</span>
            </div>
        ),
        link: (
            <a
                href='https://example.com/some-very-long-url-that-will-be-truncated'
                target='_blank'
                rel='noopener noreferrer'
                style={{
                    display: 'inline-block',
                    width: '150px',  // Set the desired width for truncation
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'middle',
                }}
            >
                https://example.com/some-very-long-url-that-will-be-truncated
            </a>
        ),
        actions: (
            <div style={{ display: 'flex', gap: '10px' }}>
                {/* Download Action */}
                <img alt='delete' src={deleteIcon} />

                {/* Copy Action */}
                <img
                    alt='copy'
                    src={copyIcon}
                    onClick={() => alert('https://example.com')}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        ),
    },
];

const DealsSettings = () => {
    const [modalShow, setModalShow] = useState(false);
    const [newDealsPageInput, setNewDealsPageInput] = useState({ name: '', theme: '' })
    const [data, setData] = useState([])
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDealsPageInput((prevCredentials) => ({ ...prevCredentials, [name]: value }));
    };

    useEffect(() => {
        getDealPageSettings().then((res) => {
            fetchDealsSettingsData();
        });
    }, []);

    const fetchDealsSettingsData = async () => {
        try {
            const response = await getDealPageSettings();
            setData(parseDealsData(response));
        } catch (error) {
            console.error('Failed to fetch deals settings:', error);
        }
    };

    const handleOnSave = () => {
        console.log({newDealsPageInput});
        createDealPageSettings(newDealsPageInput).then((res) => {
            fetchDealsSettingsData()
            setModalShow(false);
        });
    }
    
    const handleOnDelete = (id) => {
        deleteDealPageSettings(id).then((res) => {
            fetchDealsSettingsData();
        });
    }

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link).then(() => {
        });
    };

    const parseDealsData = (rawData) => {
        return rawData.map((deal) => ({
            id: deal._id,
            name: <Label style={{ marginTop: '15px' }}>{deal.name}</Label>,
            theme: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: deal.theme,  // Apply the theme color
                            marginRight: '8px',
                        }}
                    />
                    <span>{deal.theme}</span>  {/* Display the hex code */}
                </div>
            ),
            link: (
                <a
                    href={deal.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                        display: 'inline-block',
                        width: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'middle',
                    }}
                >
                    {deal.link}
                </a>
            ),
            actions: (
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* Delete Action */}
                    <img
                        alt='delete'
                        src={deleteIcon}
                        onClick={() => handleOnDelete(deal.referenceId)}  // Add delete logic here
                        style={{ cursor: 'pointer' }}
                    />
    
                    {/* Copy Action */}
                    <img
                        alt='copy'
                        src={copyIcon}
                        onClick={() => handleCopy(deal.link)}  // Copy to clipboard logic here
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ),
        }));
    };

    return (
        <>

            <Row>

                <AnchorText onClick={() => setModalShow(true)} style={{ color: '#475467' }}><img src={plusIcon} alt='plus' />Add new deals page</AnchorText>


            </Row>
            <hr />

            <Row>
                <p className="section-title" style={{ color: '#101828' }}>Deals Flow Pages</p>
            </Row>


            <DataTable columns={columns} data={data} />
            <div style={{ marginBottom: '50px' }}></div>



            <Modal show={modalShow} onSave={()=> handleOnSave()} onHide={() => setModalShow(false)} image={cardHeading} style={{ padding: '20px' }}>
                <span className="card-title">Add new deals page</span>

                <Row style={{ marginTop: '30px' }}>
                    <Col xs={23}>
                        <Label>Name</Label>
                        <TextField name='name' onChange={(e) => handleInputChange(e)} value={newDealsPageInput.name} placeholder='Name for the deals flow'></TextField>
                    </Col>

                </Row>

                <Row style={{ marginTop: '30px', alignItems: 'center' }}>
                    <Col xs={12}>
                        <Label>Select a Theme</Label>
                        <SketchPicker
                            color={newDealsPageInput.theme || '#fff'} // Default to white if no color is selected
                            onChange={(newColor) => {
                                const simulatedEvent = {
                                    target: {
                                        name: 'theme',
                                        value: newColor.hex,
                                    },
                                };
                                handleInputChange(simulatedEvent);
                            }}
                        />
                    </Col>
                    </Row>
                    <Row style={{ marginTop: '30px', alignItems: 'center' }}>
                    <Col xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: newDealsPageInput.theme || '#fff', // Display current color
                                border: '1px solid #ccc',
                                marginLeft: '10px',
                            }}
                        />
                        <span style={{ marginLeft: '10px' }}>{newDealsPageInput.theme || '#fff'}</span> {/* Display current hex code */}
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default DealsSettings;