import axios from 'axios';
import Cookies from 'js-cookie';

const path = '/deals';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },
});

export const getDeals = async () => {
    try {
        const response = await authApi.get(URL + '/listings');
        return response?.data?.deals;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};