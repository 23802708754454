import Label from '../../../components/Typorgraphy/Label';
import TextField from "../../../components/TextField";
import DatePicker from "react-datepicker";
import checkIcon from '../../../assets/images/check.svg'
import uncheckIcon from '../../../assets/images/uncheck.svg'
import "react-datepicker/dist/react-datepicker.css";
import {Row,Col,Tab,Tabs,Nav,OverlayTrigger,Tooltip} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FlowUI from '../../../pages/Dashboard/FormatPreferences/CustomFieldFlowUI/FlowUI'
import Badge from 'react-bootstrap/Badge';
import { useEffect, useState,useRef } from 'react';
import Button from '../../../components/Button';
import filtericon from '../../../assets/icons/filter.svg';
import spacingIcon from '../../../assets/icons/spacing.svg';
import calenderIcon from '../../../assets/icons/calendar.svg';
import SearchableSelect from '../../../components/SearchAbleSelect/searchAbleSelect';
import gridIcon from '../../../assets/icons/grid.svg';
import DataTable from '../../../components/DataTable';
import copyIcon from '../../../assets/icons/copy.svg';
import RelationBuilder from './RelationBuilder';
import minusSquare from '../../../assets/icons/minuxSquare.svg'
import pencilIcon from '../../../assets/images/text-input.svg'
import arrowDownRed from '../../../assets/icons/arrowDownRed.svg'
import LoadingSpinner from '../../../components/Spinner/index';
import LoadingProgressBar from '../../../components/Spinner/LoadingProgressBar'
import { createCSVMatch, createPorfolioMatch, processDeal,uploadCSVFile,getModifiers,getModifiersLendable } from '../../../api/csv';
import knowColumns from '../../../utils/knownColumns.json'

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import questionIcon from '../../../assets/images/question.svg';
import { useLocation } from 'react-router-dom';
import './style.css'
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'sonner';
const Formats = () => {

    const [userColumns,setUserColumns] = useState([]);
    const [multipleInputFiles,setMultipleInputFiles] = useState(false);
    const [relationsModal,setRelationsModal] = useState(false);
    const [multipleFilesJson,setmultipleFilesJson] = useState({});
    const [multipleFilesRelationCompleted,setMultipleFilesRelationCompleted] = useState(true);
    const [calculatedFieldStatus,setCalculatedFieldsStatus] = useState(false);
    const [completedCalculatedFields,setCompletedCalculatedFields] = useState([]);

    const [calculatedFieldModal,setCalculatedFieldModal] = useState(false);
    const [currentRow,setCurrentRow] = useState(null);
    const [currentIndex,setCurrentIndex] = useState(null);
    const [previousJson,setPreviousJson] = useState(null);

    const openCalulatedFieldModal = (item,index,previousJson) => {
        console.log("Calculated field previous JSON ", previousJson )
        if(previousJson){
            setPreviousJson(previousJson)
        }
        console.log("++++++ ITEM +++++++\n", {item})
        setCurrentIndex(index)
        setCurrentRow(item.csvColumn)
        setCalculatedFieldModal(true);
    }
    const handleMatchingCalled = useRef(false);
    const [currentCalculatedValueIndex,setCurrentCalculatedValueIndex] = useState(0);
    const [firstFormatMatchedFields,setFirstFormatMatchedFields] = useState([])
    const [progress, setProgress] = useState(0);
    const [hasModifiers,setHasModifiers] = useState(false)
    const [hasDropDownColumns, setHasDropDownColumns] = useState(false);
    const [isApiFunction,setIsApiFunction] = useState(false)
    const [fieldMatchingStatus,setFieldMatchingStatus] = useState(false)
    const [grcMatchingStatus,setGRCMatchingStatus] = useState(false)
    const [progressBar,setProgressBar] = useState(3)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [llmResponse, setLlmResponse] = useState(false);
    const [context, setContext] = useState('');
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
    const location = useLocation();
    const  dataState = location.state;
    const [matchedFields, setMatchedFields] = useState([]);
    const [unmatchedFields, setUnmatchedFields] = useState([]);
    const [loading,setLoading] = useState(true);
    const [source,setSource] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const[inputData,setInputData] = useState({currentFormat:'',source:'',assetClass:''})
    const [dropDownColumns,setDropDownColumns] = useState([]);
    const [tabs,setTabs] = useState([]);

    const handleGenerateRelations = (relations) => {
        setMultipleFilesRelationCompleted(true);
        setmultipleFilesJson(relations);
        console.log('++++++++++ + + + ++ + + + ++ + + ++ Generated Relations JSON:', JSON.stringify(relations, null, 2));
      };

    const completeCalculatedFields = () => {
        let status = true;
        firstFormatMatchedFields.forEach(item => {
            if(item.isCustom){
                console.log("Checking Calculated Field Status", item.isDone)
                if(item.isDone === false || item.isDone === undefined){
                    status = false
                }
            }
        })
        setCalculatedFieldsStatus(status)
    } 

    // GRC DropDown Dummy Data 
    // const tabs = [
    //     {
    //         csvColumn: "loan_status",
    //         grcField: "Loan Status",
    //         matchedValues: [
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             },
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             },
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             }
    //         ]
    //     },
    //     {
    //         csvColumn: "installment_recheduled",
    //         grcField: "Installment schedule ",
    //         matchedValues: [
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             },
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             },
    //             {
    //                 csvInputValue: "Active",
    //                 matchedValue: "active",
    //                 confidence: 90,
    //                 count:'158'
    //             }
    //         ]
    //     }
    // ];


    // GRC DropDown Dummy Data 

    const getCalculatedColumnValue = ( index) => {
        const item = firstFormatMatchedFields[index];
        console.log("Found Calculated Field in Format " , item)
        if(item && item.isCustom){
            return item.customFieldJSON
        }
        else{
            return null
        }
  
    }

    const changeMatchedColumn = (value,index) => {
        const selectedFieldDescription = knowColumns.find(item => item.name == value)?.description;
        console.log('Selected Field Description:', selectedFieldDescription);
        const updatedUnmatchedFields = [...unmatchedFields];
        updatedUnmatchedFields[index].matchedColumn = value;
        updatedUnmatchedFields[index].description = selectedFieldDescription;
        setUnmatchedFields(updatedUnmatchedFields);
        console.log('Updated Unmatched Fields:', updatedUnmatchedFields);
    }
      const [activeTab, setActiveTab] = useState();
      const [completedTabs, setCompletedTabs] = useState([]);

    const customWidthMatched = {'Imported File Column': '40%', 'CreditCurve Field':'30%','Actions':'30%'}
    const columnsMatched = [ 
       { name :'Imported File Column', selector: row => row.importedColumn,sortable: false},
       { name :'CreditCurve Field', selector: row => row.dv01Field,sortable: false},
       { name :'Actions', selector: row => row.actions,sortable: false}
    ]
    const dataMatched = matchedFields?.map((item,index) => {  
        return{
            id:index,
            importedColumn:item.csvColumn,
            dv01Field:item.matchedColumn + ' ('+item.description+')',
            actions:<><img src={pencilIcon} alt='delete' style={{marginRight:'10px'}} /> <img src={copyIcon} alt='copy'/></>
        }
     });
     const customWidthsUnmatched = {'Imported File Column': '12%', 'First Row Data': '10%','File Name':'17%', 'Confidence': '8%','Score 2':'7%', 'A.I Description' :'18%','Creditcurve Field': '28%', };
    const columnsUnmatched = [ 
        {name: 'Imported File Column', selector: row => row.importedColumn,sortable: false},
        {name: 'First Row Data', selector: row => row.firstRow,sortable: false},
        {name: 'File Name', selector: row => row.fileName || 'Filename',sortable: false},
        {name: 'Confidence',selector: row => row.confidence,sortable: true},
        {name: 'Score 2',selector: row => row.score_2,sortable: true},
        {name: 'A.I Description',selector: row => row.description,sortable: false}, 
        {name: 'Creditcurve Field',selector: row => row.creditCurveField,sortable: false},
    ];

    const addCaclulatedField = (payload,jsonString,index) => {
        // const tempUnmatchedFields = [...unmatchedFields];
        // tempUnmatchedFields[index].operations = payload.operations;
        // tempUnmatchedFields[index].customFieldJSON = JSON.stringify(jsonString);
        // tempUnmatchedFields[index].isCustom = true

        // console.log("Temp Unmatched Fields",tempUnmatchedFields)
        // setUnmatchedFields(tempUnmatchedFields);
        const temp = [...firstFormatMatchedFields];
        temp[index].isDone = true;
        temp[index].operations = payload.operations;
        temp[index].customFieldJSON = JSON.stringify(jsonString);
        setFirstFormatMatchedFields(temp);
        

        const tempMatchedFields = [...matchedFields];
        tempMatchedFields.push({
            csvColumn: firstFormatMatchedFields[index].csvColumn,
            description : "",
            matchedColumn : firstFormatMatchedFields[index].matchedColumn,
            isCustom : true,
            operations : payload.operations,
            customFieldJSON : JSON.stringify(jsonString)
        })
        console.log("New Calculated Field Pushed to Array : ", {tempMatchedFields})
        setMatchedFields(tempMatchedFields);
        completeCalculatedFields();
        console.log("Unmatched Fields now ",{unmatchedFields})
    }


    const columnsGRCDropDown = [ 
        {name: 'Unique Value', selector: row => row.uniqueValue,sortable: true},
        {name: 'Count',selector: row => row.count,sortable: true},
        {name: 'Confidence',selector: row => row.confidence,sortable: true}, 
        {name: 'GRC DropDown Value',selector: row => row.grcDropDownValue,sortable: false},
    ];

    const setModifierChangeValue = (e,item) => {
        console.log('Modifier Change Value:', e.target.value , item);
        const newTabs = tabs;
        newTabs.find(tab => tab.csvColumn === activeTab).matchedValues.find(matchedValue => matchedValue.csvInputValue === item.csvInputValue).matchedValue = e.target.value;
        setTabs(newTabs);
        setDropDownColumns(newTabs);
        console.log('New Tabs:', newTabs);
    }

    const currentGRCDropDown = tabs?.find(item => item.csvColumn === activeTab);
    
    const dataForGRCDropDown = currentGRCDropDown?.matchedValues?.map((item,index) => {
        return{
            id:index,
            uniqueValue:item.csvInputValue === '' ? '(Empty)' : item.csvInputValue,
            count:item.count,
            confidence: item?.modifierFlag ? 'Custom Modifier' : item.confidence + " % ",
            grcDropDownValue:<select onChange={(e) => setModifierChangeValue(e,item)} className='select'>
                {item?.modifierFlag ? item?.matchedArray?.map((value,index)=> <option value={value} key={index}>{value}</option>) : 
                <>
                 <option selected key={index}>{item.csvInputValue === '' ? 'Empty' : item?.matchedValue}</option>
                 
                <option disabled> - - - Possible Values - - -</option> {currentGRCDropDown?.allValues.map((value,index)=> <option value={value}>{value}</option>)} </>}</select>
        }
    })


    const dataForUnmatched = unmatchedFields?.map((item,index) => {
        return{
            id:index,
            llm:item.llm,
            fileName:item.fileName,
            importedColumn:item.csvColumn,
            firstRow:item.firstRowValue,
            score_2:item.score_2 + "%",
            confidence:Math.round(item.score * 100)+'%',
            description:<span style={{wordWrap: 'break-word',display:'block',whiteSpace:'normal',width:'250px'}}>{item.description}</span>,
            creditCurveField:<div style={{border:'none'}}>

                <div style={{ display: 'flex', alignItems: 'center',border:'none' }}> 
                
                <SearchableSelect
            onChange={(e) => changeMatchedColumn(e.target.value, index)}
            selectedValue={ item.matchedColumn }
            style={{ marginRight: '10px',cursor:'pointer' }}
            className=''
          >
                    
                    <optgroup label="Best Matched ">
                    <option selected value={item.matchedColumn}>{item.matchedColumn}</option>
            </optgroup>
            
            {item?.otherPossibleMatches?.length > 0 && (
              <optgroup label="Other Possible Matches">
                {item.otherPossibleMatches.map((option, index) => (
                  <option key={index} value={option.column}>{option.column}</option>
                ))}
              </optgroup>
            )}
            <optgroup label="Available GRC Fields">
              {knowColumns.map((option, index) => (
                <option key={index} value={option.name}>{option.name}</option>
              ))}
            </optgroup>
            
            
            </SearchableSelect>



            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1"><strong>Field Not Found ? </strong> <br/> Click to Alert  </Tooltip>}>
                 <img src={questionIcon} alt='Field Not Found' style={{marginRight:'10px',cursor:'pointer',marginLeft:'10px'}} onClick={()=> fieldNotFoundAlert(item.csvColumn,item.firstRowValue,item.matchedColumn,item.description)} />
            </OverlayTrigger>
            
            {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip2"><strong>Custom Calculated Field </strong> <br/> Click to Build Logic  </Tooltip>}>
            <img  src={pencilIcon} alt='delete' style={{marginRight:'10px',cursor:'pointer'}}
            onClick={ ()=> openCalulatedFieldModal(item,index,getCalculatedColumnValue(item.matchedColumn)) } />
            </OverlayTrigger> */}



            </div>
            </div>,
           
        }
    })
    
    const fieldNotFoundAlert = async (csvColumn, firstRow, bestMatched,description) => {

        Swal.fire({
            title: ' Field Not Found',
            text: `Field '${csvColumn}' not found in the GRC database. Would you like to send a feedback to add this field ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#7F56D9',
            cancelButtonColor: '#d33',
        }).then( async (result)  =>  {
            if (result.isConfirmed) {
                const url = '/api/grcFieldAlert';
                const data = {
                    csvColumn,
                    firstRow,
                    bestMatched,
                    description
                };
            
                try {
                    setLoading(true);
                    console.log('Sending field not found alert:', {data});
                    const response = await axios.post(url, data);
                    console.log('Response:', response.data);
                    toast.success('Feedback sent successfully!');
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
                finally{
                    setLoading(false)
                
                }
            }
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row.llm === true,
            style: {
                backgroundColor: '#7F56D9',
                color:'white',
                fontWeight:'bold',
                
                '&:hover': {
                    // cursor: 'pointer',
                },
            },
        }
    ]

    async function postFormatData(name, matchFields, dropDownColumns) {
        await createCSVMatch(name, matchFields, dropDownColumns)
    }

    async function postPortfolioData(uploadData, matchFields) {
        const { portfolioData, file } = uploadData

        const jsonData = await uploadCSVFile(portfolioData,file)

        if (jsonData !== null) {
            await createPorfolioMatch(jsonData.portfolioId, matchFields)
        }
    }

    async function postDealPortfolioData(portfolioId, matchFields, dropDownColumns) {
        setLoading(true)

        await createPorfolioMatch(portfolioId, matchFields, dropDownColumns)
        await processDeal(portfolioId);


        setLoading(false)
        navigate('/onboarding')
    }

    const matchAllItems = () => {
        
        setLoading(true);
       
        const updatedMatchedFields = [...matchedFields];
        const updatedUnmatchedFields = [...unmatchedFields];

        console.log('Final Matching Before Pushing ', updatedUnmatchedFields);

        // Move all unmatched items to matched fields
        updatedUnmatchedFields.forEach(field => {
            updatedMatchedFields.push({ 
                csvColumn: field.csvColumn, 
                description: field.description,
                matchedColumn: field.matchedColumn,
                isCustom : field.isCustom || false,
                operations: field.operations || null,
                fileName: field.fileName || null,
                customFieldJSON: field.customFieldJSON || null,
            });
        });

       

        setFieldMatchingStatus(true)

        // Clear unmatched fields
        setUnmatchedFields([]);

        // Update state with matched fields
        setMatchedFields(updatedMatchedFields);
        setTimeout(() => {
            setLoading(false)
        }, 700);
        updateCalculatedFields(updatedMatchedFields);
      
    };

const fetchDataFromLocalStorage = () => {
    return new Promise((resolve, reject) => {
        try {
            const data = dataState.csvData;
            if (data) {
                resolve(data);
            } else {
                reject(new Error("Data not found in local storage."));
            }
        } catch (error) {
            reject(error);
        }
    });
};


const sendDropDownColumns = async (dropDownColumns) => {
    const url = '/api/sendDropDownColumns';
    const data = {
        dropDownColumns: dropDownColumns
    };

    try {
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Response:', response.data);
        return response.data
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
};

const addCaclulatedFieldMultiple = (payload,jsonString,index) => {
    const temp = [...firstFormatMatchedFields];
    temp[index].isDone = true;
    setFirstFormatMatchedFields(temp);
    

    const tempMatchedFields = [...matchedFields];
    tempMatchedFields.push({
        csvColumn: firstFormatMatchedFields[index].csvColumn,
        description : "",
        matchedColumn : firstFormatMatchedFields[index].matchedColumn,
        isCustom : true,
        operations : payload.operations,
        customFieldJSON : JSON.stringify(jsonString)
    })
    console.log("New Calculated Field Pushed to Array : ", {tempMatchedFields})
    setMatchedFields(tempMatchedFields);
    completeCalculatedFields();
    console.log("Unmatched Fields now ",{unmatchedFields})
}


const markAsDoneFunctions = (updatedCalcFields,matchedTempConst) => {
    //////////
    const temp = updatedCalcFields;
    const tempMatchedFields = [...matchedTempConst];
   
    const tmpUpdate = temp.map(item => {
        item.operations.map(operation => {
            if (operation.type === 'rand_id_generator' || operation.type === 'rename' || operation.type === 'custom_value') {
                console.log(" - - - -%%%%% - - -  - -  -Found Normal Operation", item);
    
                // Add the matched field to tempMatchedFields
                tempMatchedFields.push({
                    csvColumn: item.csvColumn,
                    description: "",
                    matchedColumn: item.matchedColumn,
                    isCustom: true,
                    operations: item.operations,
                    customFieldJSON: item.customFieldJSON
                });
    
                // Mark the item as done
                item.isDone = true;
            }
        });
    
        return item; 
    });
    console.log(")))))))))) ) ) ) ) )) Temp Matched Fields",tmpUpdate)
     setMatchedFields(tempMatchedFields);
    // temp[index].isDone = true;
     setFirstFormatMatchedFields(tmpUpdate);


    // const tempMatchedFields = [...matchedFields];
    
    // console.log("New Calculated Field Pushed to Array : ", {tempMatchedFields})
    // setMatchedFields(tempMatchedFields);
    // completeCalculatedFields();
    // console.log("Unmatched Fields now ",{unmatchedFields})
/////////
}
const updateCalculatedFields = (matchedTempConst) =>{
    
    const calculatedFields = firstFormatMatchedFields.filter(item=>item.isCustom)
        console.log("Only Calculated Fields",calculatedFields);


        calculatedFields.map(item => {
            const tmpFieldString = JSON.parse(item.customFieldJSON);
            
            // Update nodes
            tmpFieldString.nodes.map(node => {
                if (node.data.isOperation === false) {
                    const match = unmatchedFields.find(field => field.matchedColumn === node.data.label);
                    if (match) {
                        console.log("Matched Column Found:", node.data.label);
                        node.data.label = match.csvColumn;
        
                        // Update operations that have matching inputs
                        item.operations.forEach(operation => {
                            operation.inputs = operation.inputs.map(input => 
                                input === match.matchedColumn ? match.csvColumn : input
                            );
                        });
                    }
                }
            });
        
            // Update the calculatedFields with the modified labels and operations
            item.customFieldJSON = JSON.stringify(tmpFieldString);
        });

        console.log("Calculated Fields with Modified Labels", calculatedFields);
        setFirstFormatMatchedFields(calculatedFields);
        markAsDoneFunctions(calculatedFields,matchedTempConst);
}
const fetchModifiers = async () => { 
    try {
        let matchedFieldsFormats = [];
        const response = await getModifiersLendable();
        console.log('Checking for Modifiers ', response);
        console.log('first_format',response?.formats)
        response?.formats.forEach(item => {
            console.log("================= format type ", item.type);
            item?.matchFields.forEach(matchField => {
                matchField.type = item.type; // Adding type key to each object
                matchedFieldsFormats.push(matchField);
            });
        });
        console.log("All Calculated Fields Items  from all formats : " , {matchedFieldsFormats})
        const temp = matchedFieldsFormats
        temp.map(item => {return { ...item, isDone: false }})
        console.log("All Calculated Fields Items " , temp)
        setFirstFormatMatchedFields(temp)
        return response.modifier_data;
    } catch (error) {
        console.error('Error:', error);
    }
};


const handleMatching = async () => {
    // if(dataState?.fileNames?.length > 1){
    //     setMultipleFilesRelationCompleted(false);
    //     setMultipleInputFiles(true);
    //     setRelationsModal(true);
    // }
    setIsApiFunction(true)
    console.log(dataState.csvData);
    setLoading(true);
    const firstFiveItems = dataState?.csvData[0]?.slice(0, 5);

    // Create nested array for values
    const values = [];

    // Populate the values array
    const keys = Object.keys(firstFiveItems[0]);

    keys.forEach(key => {
    const columnValues = firstFiveItems.map(item => item[key]);
    values.push(columnValues);
    });

    console.log("First 5 values" , {values});

    try {
        const data = await fetchDataFromLocalStorage();
        if (!data || data.length === 0) {
            console.log('No data found in local storage');
            setProgress('0/0');
            setLoading(false);
            return;
        }
//////
let userInputFields = [];
const transformedData = data.map((csvData, index) => {
    let tempFields = []
    const columns = Object.keys(csvData[0] || {});
    console.log("User Input Columns:", columns);
    console.log("This File Name:", dataState?.fileNames[index]);
    tempFields = columns.map(column => { return { fieldName: column,  fileName: dataState?.fileNames[index] } });
    userInputFields = userInputFields.concat(tempFields);
    const chunkedColumns = [];

    for (let i = 0; i < columns.length; i += 10) {
        chunkedColumns.push(columns.slice(i, i + 10));
    }

    return {
        fileName: dataState?.fileNames[index],  // Associate file name with the chunks
        chunks: chunkedColumns.map(chunk => {
            const transformedChunk = csvData.slice(0, 2).map(record => {
                const chunkRecord = {};
                chunk.forEach(col => {
                    chunkRecord[col] = record[col];
                });
                return chunkRecord;
            });

            return transformedChunk;
        })
    };
});
console.log("========================Final User Input fields ===========================",userInputFields)
setUserColumns(userInputFields);

console.log('Transformed Data:', transformedData);

// Calculate total number of columns
const totalColumns = transformedData.reduce((sum, fileData) => {
    const chunkColumns = fileData.chunks.reduce((chunkSum, chunk) => {
        const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
        console.log(`Chunk columns: ${numColumns}`);
        return chunkSum + numColumns;
    }, 0);
    return sum + chunkColumns;
}, 0);

console.log('Total Columns:', totalColumns);

let processedColumns = 0;
setProgress(`${2}/${totalColumns}`);
const results = [];

for (let fileData of transformedData) {
    const { fileName, chunks } = fileData;
    for (let chunk of chunks) {
        try {
            const response = await axios.post('/api/matchColumns', { csvData: chunk, values: values });
            const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
            processedColumns += numColumns;
            console.log(`Processed Columns: ${processedColumns}/${totalColumns}`);
            setProgressBar((processedColumns / totalColumns) * 100);
            setProgress(`${processedColumns}/${totalColumns}`);
            console.log("# # # # # # # # # # # ## # # ## # Loop filename",fileName)
            console.log(chunk)
            console.log("Response for this chunk",response.data)
            const tempResponse = {matched:[],unmatched: response?.data?.unmatched.map((item,idx)=> ({...item,fileName:fileName}))}
            console.log("Temp Updated Response",tempResponse)
            results.push(tempResponse);
        } catch (error) {
            console.error('Error:', error);
            const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
            processedColumns += numColumns;
            console.log(`Processed Columns: ${processedColumns}/${totalColumns}`);
            setProgress(`${processedColumns}/${totalColumns}`);
            results.push({ fileName, data: { matched: [], unmatched: [] } });
        }
    }
}



        //////////

        const matchedFields = results.flatMap(result => result.matched);
        const unmatchedFields = results.flatMap(result => result.unmatched);

       const customModifiers =  await fetchModifiers();
       console.log('Custom Modifiers CHecking :', customModifiers);

       // Check if any custom modifiers are available by matching the matchedColumn with the customModifiers

       const finalCustomModifierColumns = unmatchedFields.filter(item => customModifiers.find(modifier => modifier.grcColumn === item.matchedColumn));
       console.log('Final Custom Modifier Columns : ', {finalCustomModifierColumns});


        let grcDropDownColumns = unmatchedFields
            .filter(item => item.flag === true)
            .map(item => ({
                csvColumn: item.csvColumn,
                matchedColumn: item.matchedColumn,
                description: item.description,
                modifier: finalCustomModifierColumns.find(modifier => modifier.matchedColumn === item.matchedColumn) ? true : false
        }));

    
       



        

        console.log("GRC DropDown Columns", {grcDropDownColumns})
        if(grcDropDownColumns.length > 0) {
            setHasDropDownColumns(true)
            const processArrayData = (dataArray, keysArray) => {
                return keysArray.map(item => {
                    const key = item.csvColumn;
                    const valueCounts = dataArray.reduce((acc, obj) => {
                        if (obj[key] !== undefined) {
                            acc[obj[key]] = (acc[obj[key]] || 0) + 1;
                        }
                        return acc;
                    }, {});
            
                    const uniqueValuesArray = Object.entries(valueCounts).map(([value, count]) => ({ value, count }));
            
                    return {
                        csvColumn: item.csvColumn,
                        grcField: item.matchedColumn,
                        uniqueValues: uniqueValuesArray
                    };
                });
            };
            
            const mergedData = [].concat(...dataState?.csvData);
            const result = processArrayData(mergedData, grcDropDownColumns);
            console.log("Final GRC DropDown with Unique values and Count ",{result})

            const tempRes = await sendDropDownColumns(result);

            const tempTabs = tempRes?.columns;
            const finalTab = tempTabs.map(item => {
                return {
                     allValues: customModifiers.find(modifier => modifier.grcColumn === item.grcField) ? customModifiers.find(modifier => modifier.grcColumn === item.grcField).modifierValues: item.allValues,
                     csvColumn:item.csvColumn,
                     grcField:item.grcField,
                     modifierFlag:customModifiers.find(modifier => modifier.grcColumn === item.grcField) ? true : false,
                     matchedValues:item.matchedValues.map(matchedValue => {
                         return {
                               csvInputValue:matchedValue.csvInputValue,
                               matchedValue:customModifiers.find(modifier => modifier.grcColumn === item.grcField) ? '' : matchedValue.matchedValue ,
                                confidence:matchedValue.confidence,
                                count:matchedValue.count,  
                         }})
                }
            })
            console.log("Final Tabs", {finalTab})

            setDropDownColumns(finalTab);
            console.log("Tabs Data",tempRes?.columns)
            
            setTabs(finalTab);
            if(tempRes?.columns.length > 0){
                setActiveTab(tempRes?.columns[0].csvColumn)
            }
        }



        setMatchedFields(matchedFields);
        console.log("Payload for Unmatched Fields" , unmatchedFields)
        setUnmatchedFields(unmatchedFields);

       
        
       
    } catch (error) {
        console.error('Error:', error);
    } finally {
        setLoading(false);
        setIsApiFunction(false)
    }
};


const updateColumn = async () => { 
    setLoading(true)
    try { 
        const response = await axios.post('/api/updateColumn', { csvData: {context:context} });
       console.log("Update Column Response" ,response)
       
    //    const llm = response?.data?.response;

       if(response?.status === 200){   
        const updatedUnmatchedFields = [...unmatchedFields];
        updatedUnmatchedFields[selectedFieldIndex].matchedColumn = response?.data[0]?.name; // llm.fieldName;
        updatedUnmatchedFields[selectedFieldIndex].description = response?.data[0]?.description;
        // updatedUnmatchedFields[selectedFieldIndex].score = llm.confidence / 100;
        updatedUnmatchedFields[selectedFieldIndex].llm = true;
        setUnmatchedFields(updatedUnmatchedFields);
        handleClose();
       }


    } catch (error) {
        console.error('Error:', error);
    }
    finally{
        setLoading(false)
    }
}

const saveFormat = () => {
    // Fetch data from local storage
    let newFormatArray = []
    // const jsonData = localStorage.getItem('CSVData');
    const data = dataState.csvData;
    // Get matchedFields from the state or any other source

    // Iterate over each object in the data array
     data.map((item,index) => {
        // Create a new object to store updated column names
        const updatedItem = {};

        // Iterate over each key-value pair in the object
        Object.entries(item).forEach(([key, value]) => {
            // Check if the key matches any csvColumn name in matchedFields
            const matchedField = matchedFields.find(field => field.csvColumn === key);

            // If a match is found, update the key with the corresponding matchedColumn name
            if (matchedField) {
                updatedItem[matchedField.matchedColumn] = value;
            } else {
                // If no match is found, keep the original key
                updatedItem[key] = value;
            }
        });
        if(index < 40){
            newFormatArray.push(updatedItem)
        }
       
    });

    console.log(123,{dataState})

    // type DropdownField struct {
    //     CSVColumn    string `json:"csvColumn"`
    //     CSVValue     string `json:"csvValue"`
    //     MatchedValue string `json:"matchedValue"` // golden record, llm
    // }

    const parsedDropDownColumns = []

    for (let ddColumn of dropDownColumns){
        for (let ddColumnMatchedValues of ddColumn.matchedValues){
            console.log("Drop Down Column", {ddColumn})
            parsedDropDownColumns.push({
                modifier: ddColumn.modifierFlag ? true : false,
                csvColumn: ddColumn.csvColumn,
                matchedColumn: ddColumn.grcField,
                csvValue: ddColumnMatchedValues.csvInputValue,
                matchedValue: ddColumnMatchedValues.matchedValue
            })
        }
    }

    if (dataState.source === "format"){
        console.log("Drop Down Post Paylaod", {tabs})

       

        
        postFormatData(inputData.currentFormat, matchedFields, parsedDropDownColumns)
        navigate('/dashboard/format-preferences',{})
    } else if (dataState.source === "portfolio") {

       

        postPortfolioData(dataState.uploadData, matchedFields,parsedDropDownColumns)
        navigate('/dashboard/marketplace',{})
    }
    else if (dataState.source === "deal") {

        console.log("Deal Submission Payload",matchedFields)
    
        postDealPortfolioData(dataState.portfolioId, matchedFields,parsedDropDownColumns)
    }
    // //localStorage.setItem('CSVDataUpdated',JSON.stringify(newFormatArray))
    // navigate('/dashboard/show-portfolio',{state:{csvData:newFormatArray}})
    
};

useEffect(()=>{
    if(dataState?.csvData){
        // if (!handleMatchingCalled.current) {
        //     handleMatchingCalled.current = true;
        //     handleMatching();
        // }
        setHasModifiers(true);
        handleMatching();
       
      
           
        
       
        if (dataState?.source){
            setSource(dataState.source)
        }
        if(dataState?.formatName){
            setInputData((prevCredentials) => ({ ...prevCredentials, currentFormat: dataState.formatName }));
        }
    }
    else{
        navigate('/dashboard/add-portfolio')
    }
   
},[])

  
    const CustomInput = ({ value, onClick }) => {
        return(<input readOnly style={{width:'100%'}} value={value} onClick={onClick} name='date' type='text' className='datepicker' />)
    }
    const handleInputChange = (e) => { 
        const { name, value } = e.target;
        setInputData((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const markTabAsDone = (tabId) => {
    console.log('Marking tab as done:', tabId);

    if (!completedTabs.includes(tabId)) {
        const updatedCompletedTabs = [...completedTabs, tabId];
        setCompletedTabs(updatedCompletedTabs);

        if (updatedCompletedTabs.length === tabs?.length) {
            console.log('All tabs have been marked as done!');
            setGRCMatchingStatus(true)
        }
    }
};


  const handleClose = () => setShow(false);
  const handleShow = (index) =>{
    setSelectedFieldIndex(index)
    setShow(true)

    };

    useEffect(() => {
        console.log('LLM Progress => ', progress);
    },[progress])
  
    return (
       <div style={{width:'80%',marginLeft:'auto',marginRight:'auto',marginTop:'25px'}}>
         { <LoadingProgressBar apiFunction={isApiFunction} show={loading} progress={progress} progressBar={progressBar} />}
         
        <Row>
                <Col lg={3} md={3} sm={6} hidden={source === "deal"}>
                    <Label>Name </Label>
                    <TextField placeholder="Name " type='text' name='currentFormat'
                     onChange={(e)=> handleInputChange(e)}
                     isRequired
                     value={inputData.currentFormat}   />
                </Col>
                <Col lg={3} md={3} sm={6}>
 
                <Button type='submit' disabled={inputData.currentFormat === '' && source !== "deal"} onClick={()=> saveFormat()} label={source === "deal" ? "Save Portfolio" : "Save Format"} style={{marginLeft:'20px',marginTop:'20px'}} variant={(inputData.currentFormat === '' && source !== "deal") ? 'default' : undefined}/>
                </Col> 
                 {/* <Col lg={3} md={3} sm={6}>
                    <Label>Source</Label>
                    <div className='custom-select'>
                    <select className='select'>
                        <option>ABC</option>
                    </select>
                    </div>
                </Col> */}


                 {/* <Col lg={3} md={3} sm={6}>
                    <Label>Asset Class</Label>
                         <TextField placeholder="Asset Class " name='assetClass' onChange={(e)=> handleInputChange(e)} value={inputData.assetClass}  type="text" />
                </Col>
                 <Col lg={3} md={3} sm={6}>
                    <Label>As of</Label>
                   <DatePicker  customInput={<CustomInput/>} selected={startDate} onChange={(date) => setStartDate(date)} />
                </Col> */}

                
            </Row>

            
            {/* <Row style={{marginTop:'18px'}}>
                <Col lg={6} md={6} sm={12}>
                    <Button style={{paddingLeft:'20px',paddingRight:'20px',marginRight:'10px'}} showIconLeft iconLeft={filtericon} variant='default' label='August-2018-RPLCSV' />
                     <Button style={{paddingLeft:'16px',paddingRight:'16px',marginRight:'10px'}} showIconLeft iconLeft={calenderIcon} variant='default' label='08/31/2024' />
                      <Button style={{paddingLeft:'17px',paddingRight:'17px',marginRight:'10px'}} showIconLeft iconLeft={spacingIcon} variant='default' label='704' />
                      <Button style={{paddingLeft:'20px',paddingRight:'20px',marginRight:'0px'}} showIconLeft iconLeft={spacingIcon} variant='default' label='27' />
                </Col>
                <Col lg={6} md={6} sm={12}>
                         <Button style={{paddingLeft:'12px',paddingRight:'12px',marginRight:'0px',borderTopRightRadius:0,borderBottomRightRadius:0}} showIconLeft iconLeft={gridIcon} variant='default' label='FIELD MATCHING' />
                      <Button style={{paddingLeft:'12px',paddingRight:'12px',marginRight:'0px',borderTopLeftRadius:0,borderBottomLeftRadius:0,borderLeft:'0px'}} showIconLeft iconLeft={filtericon} variant='default' label='DATA WRALNGLING' />
                        
                </Col>
            </Row> */}


            <hr/>

                {/* Main Tabs For Normal Field Matching + GRC Dropdown Matching  */}

            <Tabs fill defaultActiveKey="fieldMatching" transition={false} className="mb-3">
                 <Tab eventKey="fieldMatching" title={<>Field Matching <img style={{marginLeft:'20px'}} src={fieldMatchingStatus? checkIcon : uncheckIcon} /></>}>
                       
                   


            <Row style={{marginTop:'30px',backgroundColor:'#f0f2f3',margin:'0px',borderRadius:'12px',padding:'16px'}}>
                <Col lg={12} md={12} sm={12}>
                    <span className='section-title'>UNMATCHED FIELDS({unmatchedFields.length})</span>
                </Col>
            </Row>
            <br/>

           <DataTable progressPending={loading}  defaultSortFieldId={3}  columns={columnsUnmatched} conditionalRowStyles={conditionalRowStyles} data={dataForUnmatched} customWidths={customWidthsUnmatched} /> 
          
            <button className='match-button' onClick={()=> matchAllItems()}><img src={arrowDownRed} alt='arrowdoen' /> Match <img src={arrowDownRed} alt='arrowdoen' /></button>
            <Row style={{marginTop:'30px',backgroundColor:'#f0f2f3',margin:'0px',borderRadius:'12px',padding:'16px'}}>
                <Col lg={12} md={12} sm={12}>
                    <span className='section-title'>MATCHED FIELDS({matchedFields.length})</span>
                </Col>
            </Row><br/>
          <DataTable progressPending={loading} columns={columnsMatched} data={dataMatched} customWidths={customWidthMatched} />
            <br/><br/>

            

            </Tab>



            {firstFormatMatchedFields.length > 0 && (

                
                ////////
                
               
                 <Tab eventKey="calculatedFields" title={<>Calculated Fields  <img style={{marginLeft:'20px'}} src={calculatedFieldStatus? checkIcon : uncheckIcon} /></>}>
                 {/* GRC Drop Down Matching Tab  */}
                 <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                 <Row>
                     <Col sm={3}>
                     <Nav variant="pills" className="flex-column">
                         {firstFormatMatchedFields?.map((calculatedField,index) => (
                            calculatedField.isCustom && (
                         <Nav.Item key={index}>
                             <Nav.Link onClick={()=> setCurrentCalculatedValueIndex(index)} eventKey={index}>
                             {calculatedField.matchedColumn}
                             <Badge bg="primary" style={{ marginLeft: '10px' }}>Required </Badge>
                             <Badge bg="success" style={{ marginLeft: '10px' }}>{calculatedField?.type} </Badge>
                             <img src={calculatedField.isDone ? checkIcon : uncheckIcon} style={{ marginLeft: '10px', color: 'green',float:'right' }} / >
                             </Nav.Link>
                         </Nav.Item>
                            )

                         ))}
                     </Nav>
                     </Col>
                     <Col sm={9}>
                     {/* <Tab.Content>
                         {tabs.map((tab) => (
                         <Tab.Pane eventKey={tab.id} key={tab.id}>
                             <button onClick={() => markTabAsDone(tab.id)}>Mark as Done</button>
                         </Tab.Pane>
                         ))}
                     </Tab.Content> */}

                           
                            {firstFormatMatchedFields[currentCalculatedValueIndex]?.isCustom && (
                                <FlowUI userFields={userColumns}
                                 savedFlow={getCalculatedColumnValue(currentCalculatedValueIndex)}
                                 onSave={addCaclulatedField}
                                 isFormat={false}
                                 itemIndex={currentCalculatedValueIndex}
                                 csvColumn={firstFormatMatchedFields[currentCalculatedValueIndex].matchedColumn} />
                            )}
                    
 
                    
                    
                     </Col>
                 </Row>
                 </Tab.Container>
                 {/* GRC Drop Down Matching Tab  */}
             </Tab>
             
            ////////

            )}
            {hasDropDownColumns && (
                <Tab eventKey="grcDropDownMatching" title={<>Dropdown Matching <img style={{marginLeft:'20px'}} src={grcMatchingStatus? checkIcon : uncheckIcon} /></>}>
                {/* GRC Drop Down Matching Tab  */}
                <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        {tabs?.map((tab) => (
                        <Nav.Item key={tab.csvColumn}>
                            <Nav.Link eventKey={tab.csvColumn}>
                            {tab.csvColumn}
                            {tab.modifierFlag && <Badge bg="primary" style={{ marginLeft: '10px' }}>Custom Modifier</Badge>}
                            
                                <img src={completedTabs.includes(tab.csvColumn) ? checkIcon : uncheckIcon} style={{ marginLeft: '10px', color: 'green',float:'right' }} / >
                            
                            </Nav.Link>
                        </Nav.Item>
                        ))}
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    {/* <Tab.Content>
                        {tabs.map((tab) => (
                        <Tab.Pane eventKey={tab.id} key={tab.id}>
                            <button onClick={() => markTabAsDone(tab.id)}>Mark as Done</button>
                        </Tab.Pane>
                        ))}
                    </Tab.Content> */}

                    <DataTable data={dataForGRCDropDown} columns={columnsGRCDropDown} />

                   
                    <Button label='Confirm' onClick={()=> markTabAsDone(activeTab)} style={{marginTop:'20px'}} />
                    </Col>
                </Row>
                </Tab.Container>
                {/* GRC Drop Down Matching Tab  */}
            </Tab>
            )}

            
            
            </Tabs>
           


            <Modal size='lg' show={relationsModal} onHide={() => { setRelationsModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Define Relationships</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RelationBuilder columns={userColumns} onGenerate={handleGenerateRelations} />
            </Modal.Body>
          </Modal>



            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Field </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>CSV Field </Form.Label>
              <Form.Control
                type="text"
                disabled
                value={selectedFieldIndex !== null ? unmatchedFields[selectedFieldIndex].csvColumn : ''}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Context</Form.Label>
              <Form.Control autoFocus as="textarea" onChange={(e) => setContext(e.target.value)} value={context} placeholder='Please provide more context about this CSV Column . . . ' rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='default' label='Cancel' onClick={()=> handleClose()}/>
            <Button label='Update' onClick={()=> updateColumn()}/>
         
        </Modal.Footer>
      </Modal>



<Modal size="lg" show={calculatedFieldModal} onHide={()=> {setCalculatedFieldModal(false); setPreviousJson(null)}}>
<Modal.Header closeButton>
      <Modal.Title>Custom Calculated Field - {currentRow || ''} </Modal.Title>
    </Modal.Header>
    <Modal.Body>
                <FlowUI userFields={userColumns} savedFlow={previousJson} onSave={addCaclulatedField} isFormat={false} itemIndex={currentIndex} csvColumn={currentRow} />
    </Modal.Body>
            
</Modal>
       </div>
    )
}
export default Formats