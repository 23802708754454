import axios from 'axios';


const path = '';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const publicApi = axios.create({
  URL,
  headers: {
    'Content-Type': 'multipart/form-data',
},
 
});

export const addDeal = async (data, files) => {
    try {
        const formData = new FormData();
        // for (let i = 0; i < files.length; i++) {
        //     formData.append('file', files[i]);
        // }
        formData.append('company', data.company);
        formData.append('company_url', data.company_url);
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('phone_number', data.phone_number);
        formData.append('requested_debt_facility_size', data.requested_debt_facility_size);
        formData.append('requested_debt_facility_type', data.requested_debt_facility_type);
        formData.append('requesting_organization', data.requesting_organization);

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
          }

          
        const response = await publicApi.post(URL + '/deals/submit', formData);

        return response.data;
    } catch (error) {
        return error?.response?.data || error.message;
    }
};

export const fetchDealPageData = async (clientId) => {
    try {
        const response = await publicApi.get(URL + '/deals/page-data?clientId=' + clientId);
        return response.data;
    } catch (error) {
        return error?.response?.data || error.message;
    }
};

export default publicApi;