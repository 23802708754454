import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import PrivateRoutes from "./utils/PrivateRoutes";

import { Toaster } from "sonner";
import './assets/css/App.scss';
import { ThemeProvider, useTheme } from "./utils/ThemeContext";

import { getOnboardingStatus } from '../src/api/onboarding';

//Authentication Pages Routes
import ForGotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import VerifyEmail from "./pages/Authentication/ForgotPassword/ForgotStep2";
import SetNewPassword from "./pages/Authentication/ForgotPassword/ForgotStep3";
import PasswordResetSuccess from "./pages/Authentication/ForgotPassword/ForgotStep4";
import Waiting from "./pages/Authentication/ForgotPassword/waiting";
import Login from "./pages/Authentication/Login/Login";
import AcceptDealInvite from "./pages/Authentication/Register/AcceptDealInvite";
import AcceptInvite from "./pages/Authentication/Register/AcceptInvite";
import Register from "./pages/Authentication/Register/Register";
import DealFlowForm from "./pages/Dashboard/DealsFlow/dealFlowForm";
import ThankYou from "./pages/Dashboard/Thankyou/ThankYou";
//Dashboard Pages Routes
import VerticalTabs from "./components/PageComponents/Settings/VerticalNav";
import MainLayout from "./pages/Dashboard";
import AddPortfolio from "./pages/Dashboard/AddPortfolio/index";
import ShowPortfolio from "./pages/Dashboard/AddPortfolio/portfolio";
import Chats from "./pages/Dashboard/Chats";
import Conversations from "./pages/Dashboard/Conversations";
import DealFlow from "./pages/Dashboard/DealsFlow";
import Definitions from "./pages/Dashboard/Definitions";
import Dashboard from "./pages/Dashboard/Home";
import ListingDetails from "./pages/Dashboard/ListingDetails/index";
import DealDetail from "./pages/Dashboard/DealDetails/index";
import OnboardingDealMatchPage from "./pages/Dashboard/Onboarding/DealFormats";
import OnboardingMatchPage from "./pages/Dashboard/Onboarding/Formats";
import OnboardingPage from "./pages/Dashboard/Onboarding/index";
import SetFormat from "./pages/Dashboard/SetFormat/index";
import Settings from "./pages/Dashboard/Settings/index";
import Support from "./pages/Dashboard/Support";

import Cookies from 'js-cookie';
import LoaderSpinner from "../src/components/Spinner";



const fetchOnboardingStatus = async (state) => {
  if (!state) {
    const onboarding = await getOnboardingStatus()
    if (onboarding && onboarding.onboarding_complete) {
      return true
    }
  }
  return false;
};

const ProtectedRoute = ({ element }) => {
  return element
  // const [isLoading, setIsLoading] = useState(true);
  // const [isOnboarded, setisOnboarded] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   const checkOnboarded = async () => {
  //     const onboardingstate = Cookies.get('onboardingState')

  //     Cookies.remove('onboardingState')

  //     const result = await fetchOnboardingStatus(onboardingstate);
  //     setisOnboarded(result);
  //     setIsLoading(false);
  //   };
  //   checkOnboarded();
  // }, []);

  // if (isLoading) {
  //   return <LoaderSpinner show={isLoading} />
  // }
  // return isOnboarded ? element : <Navigate to="/onboarding" />;
};

const App = () => {
  const { isDarkMode } = useTheme();



  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : ''}`}>

      <Router>
        <Routes>

          {/* Protected Routes  */}
          <Route element={<PrivateRoutes />}>

            {/* Dashboard Routes */}
            <Route path="/dashboard" element={<ProtectedRoute element={<MainLayout />} />}>
              <Route index element={<Dashboard />} />
              <Route path="settings" element={<ProtectedRoute element={<Settings />} />} />
              {/* <Route path="teams" element={<ProtectedRoute element={<Teams />} />} /> */}
              <Route path="definition-management" element={<ProtectedRoute element={<Definitions />} />} />
              {/* <Route path="marketplace" element={<ProtectedRoute element={<Marketplace />} />} /> */}
              <Route path="conversations" element={<ProtectedRoute element={<Conversations />} />} />
              <Route path="deal-flow" element={<ProtectedRoute element={<DealFlow />} />} />
              {/* <Route path="format-preferences" element={<ProtectedRoute element={<FormatPreferences />} />} /> */}
              <Route path="chats" element={<ProtectedRoute element={<Chats />} />} />
              <Route path="vertical" element={<ProtectedRoute element={<VerticalTabs />} />} />
              <Route path="support" element={<ProtectedRoute element={<Support />} />} />
              <Route path="add-portfolio" element={<ProtectedRoute element={<AddPortfolio />} />} />
              <Route path="listing-details" element={<ProtectedRoute element={<ListingDetails />} />} />
              <Route path="deal-details" element={<ProtectedRoute element={<DealDetail />} />} />
              <Route path="show-portfolio" element={<ProtectedRoute element={<ShowPortfolio />} />} />
              <Route path="set-format" element={<ProtectedRoute element={<SetFormat />} />} />
            </Route>
            {/* Dashboard Routes */}

            {/* Dashboard Routes */}
            <Route element={<OnboardingPage />} path="/onboarding" />
            <Route element={<OnboardingMatchPage />} path="/onboarding/match" />
            <Route element={<OnboardingDealMatchPage />} path="/onboarding/deal-match" />

          </Route>

          {/* Protected Routes  */}


          {/* Authentication Routes */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route element={<Login />} path="/login" />
          <Route element={<Waiting />} path="/waiting" />
          <Route element={<Register />} path="/register" />
          <Route element={<ForGotPassword />} path="/forgot" />
          <Route element={<VerifyEmail />} path="/verify-email" />
          <Route element={<SetNewPassword />} path="/reset-password" />
          <Route element={<PasswordResetSuccess />} path="/password-reset" />
          <Route element={<AcceptInvite />} path="/accept-invite" />
          <Route element={<AcceptDealInvite />} path="/accept-deal-invite" />
          <Route element={<DealFlowForm />} path="/deal-flow-form" />
          <Route element={<ThankYou />} path="/thank-you" />
          {/* Authentication Routes */}

        </Routes>
      </Router>
      <Toaster position="top-center" closeButton={true} richColors icons={true} />
    </div>
  );
}
const ExportedApp = () => (
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

export default ExportedApp;