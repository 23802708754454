// api/chats.js
import axios from 'axios';
import Cookies from 'js-cookie';

const path = '/settings';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json', // Set to 'multipart/form-data' for file uploads
        'Authorization': `Bearer ${Cookies.get('token')}`
    },
});

export const getDealPageSettings = async () => {
    try {
        const response = await authApi.get('/deals');
        return response?.data?.dealPages;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

export const getDealPageSettingsById = async (id) => {
    try {
        const response = await authApi.get(`/deals/${id}`);
        return response?.data?.dealPage;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

// createChat function
export const createDealPageSettings = async (settingsData) => {
    try {
        console.log({settingsData})
        // Send POST request to create a new chat
        const response = await authApi.post('/deals', settingsData);
        return response?.data;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

export const deleteDealPageSettings = async (id) => {
    try {
        const response = await authApi.delete(`/deals/${id}`);
        return response?.data;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
}