// Button.js
import React from 'react';

import './Button.scss';

const Button = ({showIconLeft = false, iconLeft = null, onClick, label, disabled = false, style={},type="button",variant="primary",id="" }) => {
    return (<button
    id={id}
    onClick={onClick}
    variant={variant}
    type={type}
    disabled={disabled}
    style={style}
    className={`button ${variant === 'default' ? 'default' : ''}`}>
  {showIconLeft ? <img src={iconLeft} alt='ico' style={{width:'15px'}}/> : ''}  {label}
    </button>);
};
export default Button;
