import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/Spinner/index';
import Title from "../../../components/Typorgraphy/Title"

const DealDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedDeal, setSelectedDeal] = useState();
    const [loading, setLoading] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(true);

    useEffect(() => {
        if (location.state && location.state.selectedDeal) {
            setSelectedDeal(location.state.selectedDeal);
        } else {
            navigate('/dashboard/deal-flow');
        }
        const iframe = document.getElementById('myIframe');
        iframe.onload = handleIframeLoaded;

    }, [])

    const handleIframeLoaded = () => {
        setIframeLoading(false);
    };


    return (
        <>
            {<LoadingSpinner show={loading} />}

            <div className="row" style={{ borderBottom: '1px solid #EAECF0', paddingBottom: '5px', marginBottom: '20px' }}>
                <div className="col-md-7">
                    <Title>Deal Details</Title>
                </div>

            </div>

            <Title style={{ fontSize: '17px' }}>Deal Details</Title>

            <Row>
                <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                    {iframeLoading && (
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <LoadingSpinner show={iframeLoading} />
                        </div>
                    )}

                    <iframe
                        id="myIframe"
                        src={`https://chatapi.creditcurve.ai?path=deals/${selectedDeal?.referenceId}`}

                        style={{
                            width: '100%',
                            height: '605px',
                            border: 'none',
                            display: loading ? 'none' : 'block',
                            overflow: 'hidden'
                        }}
                    ></iframe>
                </div>
            </Row>


        </>
    )
}
export default DealDetails