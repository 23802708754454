import React, { useState, useCallback, useEffect } from 'react';
import { ReactFlow, ReactFlowProvider, addEdge, useNodesState, useEdgesState, Background, Controls, Handle } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { Button } from 'react-bootstrap';

const RelationBuilder = ({ columns, onGenerate }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [relationEdges, setRelationEdges] = useState([]);

  // Initialize nodes based on columns provided
  useEffect(() => {
    const initializeNodes = () => {
      const fileNodes = [];
      const files = [...new Set(columns.map((col) => col.fileName))];

      files.forEach((file, index) => {
        const fileColumns = columns.filter((col) => col.fileName === file);

        fileNodes.push({
          id: `file-${index}`,
          type: 'default',
          data: {
            label: (
              <div>
                <strong>{file}</strong>
                {fileColumns.map((col, i) => (
                  <div key={i}>
                    <Handle
                      type="source"
                      position="right"
                      id={`${file}-${col.fieldName}`}
                      style={{ background: '#555', marginBottom: '5px' }}
                    />
                    {col.fieldName}
                  </div>
                ))}
              </div>
            ),
          },
          position: { x: 150 * (index + 1), y: 100 },
        });
      });

      setNodes(fileNodes);
    };

    initializeNodes();
  }, [columns, setNodes]);

  // Handle edge addition
  const onConnect = (params) => {
    setEdges((eds) => addEdge(params, eds));
    setRelationEdges((edges) => [...edges, params]);
  };

  const handleGenerate = () => {
    const result = relationEdges.reduce((acc, edge) => {
      const [file1, column1] = edge.source.split('-');
      const [file2, column2] = edge.target.split('-');

      if (!acc[file1]) acc[file1] = {};
      acc[file1][column1] = { linked_file: file2, linked_column: column2 };

      return acc;
    }, {});

    onGenerate(result);
  };

  return (
    <ReactFlowProvider>
      <div style={{ height: '500px', border: '1px solid black', marginBottom: '10px' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          fitView
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      <Button variant="primary" className="mt-3" onClick={handleGenerate}>
        Confirm Relations
      </Button>
    </ReactFlowProvider>
  );
};

export default RelationBuilder;
