import React, { useEffect } from "react";
import Title from '../../../components//Typorgraphy/Title'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import './settings.css';

import { useLocation } from 'react-router-dom';
import Formats from "../../../components/PageComponents/Settings/Formats";
import Integrations from "../../../components/PageComponents/Settings/Integrations"; 
import Notifications from "../../../components/PageComponents/Settings/Notifications";
import Billing from "../../../components/PageComponents/Settings/Billing";
import DealsSettings from "../../../components/PageComponents/Settings/DealsSettings";
import CompanyProfile from "../../../components/PageComponents/Settings/CompanyProfile";
import GRCModifiers from "../GRC Modifiers/GRCModifiers";

import Teams from '../../../components/PageComponents/Settings/Teams'
const Settings = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');

const [active,setActive] = React.useState(1);

const handleTabChange = (index)   => {
    setActive(index);
}
useEffect(()=>{
   if(tab){
    setActive(parseInt(tab));
   }
},[])


const tabs = [
            // {name: 'Definitions', id: 0, component:<></>},
            // {name: 'Formats', id: 1,component:<Formats/>},
            // {name: 'Company Profile', id: 2,component:<CompanyProfile/>},
            // {name: 'Team', id: 3,component:<><Teams/></>},
            {name: 'Notifications', id: 0,component:<><Notifications/></>},
            // {name: 'Integrations', id: 5,component:<><Integrations/></>},
            {name: 'Deals Settings', id: 1,component:<><DealsSettings/></>},
            // {name: 'Billing', id: 7,component:<><Billing/></>}
           
        ]
    return (
        <>
           <Title>Settings</Title>
            <ButtonGroup className="group" aria-label="Settings-Tabs">
                {tabs.map((tab) => {
                    return (
                        <Button key={tab.id} onClick={()=> handleTabChange(tab.id)} className={`btnTabbedGroup ${tabs[active].name === tab.name ? 'currentTab' :''}`}>{tab.name}</Button>
                    )
                })}
            </ButtonGroup>

           {tabs[active].component}

        </>
    );
}

export default Settings;



