import Papa from 'papaparse'; // Import PapaParse library
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Swal from 'sweetalert2';
import { addDeal, fetchDealPageData } from '../../../api/public';
import Button from "../../../components/Button";
import LoaderSpinner from '../../../components/Spinner';
import TextField from '../../../components/TextField';
import Label from '../../../components/Typorgraphy/Label';
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';

const DealFlowForm = ({ onSubmit }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const client = queryParams.get('client') || '';
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState(['']);
  const [errorMessage, setErrorMessage] = useState(null);
  const [pageTheme, setPageTheme] = useState({ name: null, color: null }); // Default theme
  const [formData, setFormData] = useState({
    websiteUrl: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    debtFacilitySize: '',
    debtFacilityType: ''
  });
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  // Load saved form data from localStorage on component mount
  useEffect(() => {
    setLoading(true);
    fetchDealPageData(client).then((response) => {
      if (response) {
        const updated = {
          name: response?.name || null,
          color: response?.theme || null
        }
        console.log({updated})
        setPageTheme(updated)
        setLoading(false);
      }
    });


    const savedFormData = localStorage.getItem('dealFormData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('dealFormData', JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      const updatedValues = checked
        ? [...formData[name], value]
        : formData[name].filter(item => item !== value);

      setFormData({ ...formData, [name]: updatedValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target.files)
    const selectedFile = e.target.files;
    setFile(selectedFile);
  };

  const handleUploadButtonClick = () => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.multiSelectCheckboxClicked,
      location: "Onboarding Questionnaire",
      buttonName: 'Upload Loan Tape'
    });
    fileInputRef.current.click();
  };

  function isUrlValid(userInput) {
    const res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
  }

  const convertFileToJsonAndSave = async (file, completedQuestionnaireData) => {
    setLoading(true);

    const csvDataArray = [];
    for (let i = 0; i < file.length; i++) {
      const currentFile = file[i];
      const csvData = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const content = event.target.result;
          const jsonData = csvToJson(content);
          if (jsonData !== null) {
            // resolve(trimArrayToMax40(jsonData));
            resolve(jsonData);
          } else {
            reject(new Error('Failed to parse CSV data'));
          }
        };
        reader.readAsText(currentFile);
      }).catch(error => {
        console.error('Error:', error);
        return null;
      });

      if (csvData !== null) {
        csvDataArray.push(csvData);
      }
    }

    const portfolioData = {
      listed: false
    };
    console.log(portfolioData)

    const invitesData = emails.map(email => {
      return {
        email,
        companyName: formData.companyName,
      }
    });
    setLoading(false);
    navigate('/onboarding/match', { state: { formData, invitesData, completedQuestionnaireData, csvData: csvDataArray, source: "portfolio", uploadData: { portfolioData, file } }, onComplete: onComplete });
  };

  const csvToJson = (csvContent) => {
    const { data, errors } = Papa.parse(csvContent, { header: true, skipEmptyLines: true }); // Parse CSV with PapaParse
    if (errors.length) {
      console.error("Invalid CSV File/Data !", errors);
      const errorString = errors.map((error) => {
        return `Row # ${error.row}   ${error.message}`
      }).join("\n");
      Swal.fire({
        title: 'Invalid CSV File/Data !',
        animation: true,
        text: errorString,
      });
      return null;
    }
    return data;
  };

  function trimArrayToMax40(array) {
    if (array.length > 40) {
      return array.slice(0, 40);
    } else {
      return array;
    }
  }

  const handleSubmit = async (e) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Questionnaire",
      buttonName: 'Continue'
    });
    e.preventDefault();

    const requiredFields = [
      'websiteUrl',
      'companyName',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'debtFacilitySize',
      'debtFacilityType'
    ];

    const missingFields = requiredFields.filter(field => {
      const value = formData[field];
      return value === undefined || value === '' || (Array.isArray(value) && value.length === 0);
    });

    if (missingFields.length > 0) {
      // alert('Please fill out all required fields:', missingFields);
      toast.error('Please fill out all required fields: ' + missingFields.join(', '));
      return;
    }

    if (!isUrlValid(formData['websiteUrl'])) {
      toast.error('Invalid website URL.');
      return;
    }

    if (!file) {
      // alert('Please upload a file');
      toast.error('Please upload a file');
      return;
    }
    setLoading(true);
    setErrorMessage(null);

    try {
      // await convertFileToJsonAndSave(file, resultArray);
      const dealData = {
        company: formData.companyName,
        company_url: formData.websiteUrl,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        requested_debt_facility_size: formData.debtFacilitySize,
        requested_debt_facility_type: formData.debtFacilityType,
        requesting_organization: client
      };
      console.log(1230000000000, { dealData })

      const response = await addDeal(dealData, file);

      console.log(1243, { response })
      // Handle response here
      if (response?.message === "Deal stored successfully") {
        // toast.success('Deal added successfully! Please check your email for further instructions.');
        localStorage.removeItem('dealFormData'); // Clear localStorage on success
        navigate('/thank-you'); // Redirect to the thank-you page
      } else {
        // setErrorMessage(response?.error || 'Failed to add the deal. Please try again.')
        if (response?.error) {
          toast.error(response?.error || 'Failed to add the deal. Please try again.');
        }
      }
    } catch (error) {
      toast.error('Error submitting form: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  async function onComplete() {
    await onSubmit();
  }

  if (loading) {
    return <LoaderSpinner show={loading} />;
  } else {

    return (
      <div>
        {/* <Header title="Deal Flow " description='Please fill out the form below' /> */}
        <form onSubmit={handleSubmit}>
          <Container>
            <div style={{ backgroundColor: 'white', height: 'auto', width: '95%', marginLeft: 'auto', marginRight: 'auto', border: '1px solid #EAECF0', borderRadius: '12px', marginBottom: '15px', marginTop: '15px', padding: '20px' }}>
              {/* Body */}

              {errorMessage && (
                <div style={{ color: 'red', marginTop: '10px', textAlign: 'left', marginBottom: '15px' }}>{errorMessage}</div>
              )}
              <Row style={{ marginTop: '30px' }}>
                <Col xs={12} md={6}>
                  <Label>First Name </Label>
                  <TextField isRequired type='text' placeholder='First Name ' id="firstName" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                </Col>
                <Col xs={12} md={6}>
                  <Label>Last Name </Label>
                  <TextField isRequired type='text' placeholder='Last Name' id='lastName' name='lastName' value={formData.lastName} onChange={handleInputChange} />
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={12} md={6}>
                  <Label>Email </Label>
                  <TextField isRequired type='email' placeholder='Your Email Address ' id="email" name="email" value={formData.email} onChange={handleInputChange} />
                </Col>
                <Col xs={12} md={6}>
                  <Label>Phone Number </Label>
                  <TextField isRequired type='text' placeholder='+1 (048) 234-5689' id='phoneNumber' name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} />
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={12} md={6}>
                  <Label>Company Name </Label>
                  <TextField isRequired type='text' placeholder='Your Company Name ' id="companyName" name="companyName" value={formData.companyName} onChange={handleInputChange} />
                </Col>
                <Col xs={12} md={6}>
                  <Label>Company Website URL: </Label>
                  <TextField isRequired type='text' placeholder='www.yoursite.com' id='websiteUrl' name='websiteUrl' value={formData.websiteUrl} onChange={handleInputChange} />
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={12} md={6}>
                  <Label>Requested Debt Facility Size: </Label>
                  <TextField isRequired type='text' placeholder='Debt Facility Size ' id='debtFacilitySize' name='debtFacilitySize' value={formData.debtFacilitySize} onChange={handleInputChange} />
                </Col>
                <Col xs={12} md={6}>
                  <Label>Requested Debt Facility Type: </Label>
                  <TextField isRequired type='text' placeholder='Debt Facility Type ' id='debtFacilityType' name='debtFacilityType' value={formData.debtFacilityType} onChange={handleInputChange} />
                </Col>
              </Row>
              <Row style={{ marginTop: '30px' }}>
                <Col xs={12} md={12}>
                  <Label>Please Upload Your Loan Tape File & Transaction history </Label>
                  {file && (
                    <div>
                      <Label>Selected Files:</Label>
                      <ul>
                        {Array.from(file).map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
                  <Button style={{ backgroundColor: pageTheme.color ? pageTheme.color : '#35C187', border: 'none' }} onClick={handleUploadButtonClick} label='Upload Loan Tape ' />
                </Col>
              </Row>
              {/* Body */}
            </div>
          </Container>
          {/* Footer */}

          <Container>
            <div style={{ backgroundColor: 'white', borderRadius: '12px', border: '1px solid #EAECF0', padding: '10px', width: '95%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '15px' }}>
              <Row>
                <Col>
                  {/* <Paragraph style={{fontWeight:'500', margin: '10px'}}>© CreditCurve 2024</Paragraph> */}
                </Col>
                <Col>
                  <Button label='Submit ' type='submit' style={{ float: 'right', backgroundColor: pageTheme.color ? pageTheme.color : '#35C187', border: 'none' }} />
                </Col>
              </Row>
            </div>
          </Container>
          {/* Footer */}

        </form>
      </div>
    );
  };
}

  export default DealFlowForm;
