// api/chats.js
import axios from 'axios';
import Cookies from 'js-cookie';

const path = '/chats';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'multipart/form-data', // Set to 'multipart/form-data' for file uploads
        'Authorization': `Bearer ${Cookies.get('token')}`
    },
});

export const getChats = async () => {
    try {
        const response = await authApi.get('/listings');
        return response?.data?.chats;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

export const getChatById = async (id) => {
    try {
        const response = await authApi.get(`/${id}`);
        return response?.data?.chat;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

// createChat function
export const createChat = async (chatData, files) => {
    try {
        const formData = new FormData();
        
        // Append chatData fields to the formData
        for (const key in chatData) {
            formData.append(key, chatData[key]);
        }

        // Append files (if any) to the formData
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
          }

        // Send POST request to create a new chat
        const response = await authApi.post('/submit', formData);
        return response?.data;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
};

export const deleteChat = async (id) => {
    try {
        const response = await authApi.delete(`/${id}`);
        return response?.data;
    } catch (error) {
        console.log(error);
        // Handle error as needed
    }
}
