import React, { useState, useEffect, useRef } from 'react';
import Button from "../../../components/Button";
import './chats.css';
import { getChats, createChat, deleteChat } from '../../../api/chats';
import Label from '../../../components/Typorgraphy/Label';
import {  Row, Col } from 'react-bootstrap';
import deleteIcon from '../../../assets/icons/delete.svg';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { toast } from "sonner";

export default function Chats() {
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    chatName: '',
    description: '',
    file: null
  });
  const fileInputRef = useRef(null);
  const intro = introJs();

  const hasSeenTour = localStorage.getItem('hasSeenTour') || false;

  useEffect(() => {
    // Initialize the tour
    const intro = introJs();

    if(!hasSeenTour) {
        // Set up the steps of the tour
    intro.setOptions({
      scrollToElement: false,
      steps: [
        {
          element: '#createChatButton', // Step 1: Create New Chat button
          intro: 'Start by creating a new Co-pilot Chat !',
          position: 'bottom',
        },
      ],
      showStepNumbers: false, // Hide step numbers
      showButtons: false, // Hide the navigation buttons
      showBullets: false, // Hide the bullets
      hideNext: true, // Hide the Next button
      hidePrev: true, // Hide the Previous button
    });

    // Start the tour (show first tooltip on page load)
    intro.start();

    // Event listener for "Create New Chat" button (already opens modal)
    const createChatButton = document.getElementById('createChatButton');
    if (createChatButton) {
      createChatButton.addEventListener('click', () => {
        intro.nextStep(); // Move to the next tooltip when "Create New Chat" is clicked
      });
    }

    }
  }, []);


  const openChat = (conv) => {
    setSelectedChat(conv)

    if (!hasSeenTour) {
      intro.setOptions({
        scrollToElement:false,
        steps: [
          {
            element: '#chat-details', // Step 1: Create New Chat button
            intro: 'Start chatting with your csv Files . . .',
            position: 'left',
          },
        ],
        showStepNumbers: false, // Hide step numbers
      showButtons: false, // Hide the navigation buttons
      showBullets: false, // Hide the bullets
      hideNext: true, // Hide the Next button
      hidePrev: true, // Hide the Previous button
      });
  
      // Start the tour (show first tooltip on page load)
      intro.start();
  
      const createChatButton = document.getElementById('chat-details');
      if (createChatButton) {
        createChatButton.addEventListener('click', () => {
          intro.exit() // Move to the next tooltip when "Create New Chat" is clicked
          localStorage.setItem('hasSeenTour', 'true');
        });
      }
      intro.oncomplete(() => {
        localStorage.setItem('hasSeenTour', 'true');
      });

      // Mark the tour as seen if the user exits early
      intro.onexit(() => {
        localStorage.setItem('hasSeenTour', 'true');
      });
    }

    


  }
  // Fetch chat listings when the component mounts
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await getChats();
        setChats(response);
      } catch (error) {
        console.error("Failed to fetch chats:", error);
      }
    };
    fetchChats();
  }, []);

  const handleCreateChat = () => {
  
    setIsCreateFormOpen(true);

   
  };

  const handleCloseForm = () => {
    setIsCreateFormOpen(false);
    setFormData({
      chatName: '',
      description: '',
      file: null
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const validFiles = [];

    // Iterate over selected files and check their size
    for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > MAX_FILE_SIZE) {
            toast.error(`File ${file.name} exceeds the 50MB limit and will not be uploaded.`);
        } else {
            validFiles.push(file); // Only add valid files to the array
        }
    }

    // Set valid files only (if you are storing files in state)
    if (validFiles.length > 0) {
        setFile(validFiles);
    } else {
        setFile(null); // No valid files
    }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create new chat using API
      const response = await createChat({
        chatName: formData.chatName,
        description: formData.description
      }, file);

      console.log('Chat created:', response);
      handleCloseForm();
     

      // Optionally refetch the list of chats after creating a new one
      const updatedChats = await getChats();
      setChats(updatedChats);

      if (!hasSeenTour) {
        intro.setOptions({
          scrollToElement:false,
          steps: [
            {
              element: '#chat-list', // Step 1: Create New Chat button
              intro: 'Select Chat to start chatting',
              position: 'left',
            },
          ],
          showStepNumbers: false, // Hide step numbers
        showButtons: false, // Hide the navigation buttons
        showBullets: false, // Hide the bullets
        hideNext: true, // Hide the Next button
        hidePrev: true, // Hide the Previous button
        });
    
        // Start the tour (show first tooltip on page load)
        intro.start();
  
        const createChatButton = document.getElementById('chat-list');
        if (createChatButton) {
          createChatButton.addEventListener('click', () => {
            intro.exit() // Move to the next tooltip when "Create New Chat" is clicked
          });
        }
      }

     

    } catch (error) {
      console.error('Failed to create chat:', error);
    }
  };

  const handleDeleteChat = async (chatId) => {
    try {
      // Delete chat using API
      const response = await deleteChat(chatId);
      console.log('Chat deleted:', response);

      // Optionally refetch the list of chats after deleting one
      const updatedChats = await getChats();
      setChats(updatedChats);

      // Clear the selected chat if it was deleted
      if (selectedChat?.referenceId === chatId) {
        setSelectedChat(null);
      }
    } catch (error) {
      console.error('Failed to delete chat:', error);
    }
  }

  return (
    <div className="chats-container">
      <div className="container-fluid">
      <Row>
        {/* Sidebar */}
        <Col md={3} sm={12} className=" p-3" style={{borderRight:'1px solid #e0e0e0'}}>
          <h2 className="sidebar-title">Chats</h2>
          <div className="chat-list" id="chat-list">
            {chats?.map((conv,index) => (
              <Row>
                <Col sm={10} md={10}>
                  <Button
                   id={`conversation${index}`}
                    style={{ marginBottom: '10px', textAlign: 'left', width: '100%' }}
                    key={conv.referenceId}
                    variant={selectedChat?.referenceId === conv.referenceId ? 'primary' : 'default'}
                    onClick={() => openChat(conv) }
                    className="w-100 mb-2 text-start"  // This ensures the button takes up the full width
                    label={`💬 ${conv.chatName}`}
                  >
                    <span role="img" aria-label="chat icon">💬</span> {conv.chatName}
                  </Button>
                </Col>

                <Col sm={2} md={2} className="d-flex align-items-center">
                  <img
                    alt='edit'
                    style={{ cursor: 'pointer', marginLeft: '0', marginBottom: '10px' }}  // Align to left
                    onClick={() => handleDeleteChat(conv.referenceId)}
                    src={deleteIcon}
                    className='downloadButton'
                  />
                </Col>
              </Row>
            ))}
          </div>
        </Col>

        {/* Main Content */}
        <Col md={9} sm={12} className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h1 className="page-title">{selectedChat ?selectedChat.name : ""}</h1>
            <Button id="createChatButton" onClick={handleCreateChat} label="+ Create New Chat " variant="success" className="float-right">
            
            </Button>
          </div>

          <div className="chat-details" id='chat-details' style={{height:'100vh',zIndex:'9999'}}>
            {selectedChat ? (
              <div>
                <h2 className="chat-title">{selectedChat.name}</h2>
                <p>{selectedChat.name}</p>

                {/* Iframe */}
                <iframe
                id='chat-iframe'
                  src={`https://chatapi.creditcurve.ai?path=chats/${selectedChat.referenceId}`}
                  className="w-100"
                  style={{ height: '750px', border: 'none' }}
                ></iframe>
              </div>
            ) : (
              <div className="text-center p-5 bg-light rounded">
                <h3>Select or create a new chat to start chatting</h3>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>

      {isCreateFormOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="modal-title">Create New Chat</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="chatName">Name</label>
                <input
                  type="text"
                  id="chatName"
                  className='form-control'
                  name="chatName"
                  value={formData.chatName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                className='form-control'
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
              {file && (
                  <div>
                    <Label>Selected Files:</Label>
                    <ul>
                      {Array.from(file)?.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <label htmlFor="file">Upload File</label>
                <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
                <Button id='uploadFilesButton' style={{ backgroundColor:  '#0d1f41', border: 'none' }} onClick={handleUploadButtonClick} label='Upload Files ' />
                {/* <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  required
                /> */}
              </div>
              <div className="form-actions">
                <Button onClick={handleCloseForm} style={{ float: 'left', marginLeft: '10px', marginRight: '10px', marginTop: '20px' }} className='float-left' variant='default' label='Cancel'></Button>
                <Button id='createSubmitButton' type='submit' style={{ float: 'right', marginRight: '10px', marginTop: '20px' }} className='float-right' label='Create'></Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
