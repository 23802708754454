import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import favouriteIcon from '../../../assets/icons/favourite.svg';
import LocationIcon from '../../../assets/icons/location.svg';
import Button from '../../../components/Button';
import './listing.css';

import LoaderSpinner from "../../../components/Spinner";


const DealFlowListing = ({ dealData, image, favourite = false, onDelete }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        console.log('DealFlowListing', dealData)
    }, [])

    return (<>
        <LoaderSpinner show={loading} />

        <Row className="listing-card">

            <Col xs={4} className="image-box">
                <img src={image} style={{ maxWidth: '180px', borderRadius: '10px' }} alt="listing" className="listing-image" />
            </Col>
            <Col xs={8} className="listing-description">
                <p className="text-listing-type">Deal</p>
                <img src={favouriteIcon} className="listing-fav-icon" />
                <span className="text-listing-title">{dealData?.company} Deal</span>

                <p className="listing-description">{dealData?.company_url}</p>
                <img src={LocationIcon} className="listing-location-icon" />
                <span className="listing-description">U.S.A</span>

                <Button onClick={() => {
                    navigate('/dashboard/deal-details', { state: { selectedDeal: dealData } })
                }}
                    variant="default" label="View Details"
                    style={{float: 'right' }} />

            </Col>
        </Row>






    </>)



}

export default DealFlowListing;