import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDeals } from '../../../api/deals';
import listingImage from '../../../assets/images/logoMain3.png';
import Button from "../../../components/Button";
import Listing from './listing';
import LoaderSpinner from "../../../components/Spinner";
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Title from '../../../components/Typorgraphy/Title';
import './dealflow.css';

const DealsFlow = () => {
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const navigate = useNavigate();
  async function fetchDeals() {
        // to get all the formats (this will not contain data, it will only return array of skyflow_id and name for each csv record)
    const deals = await getDeals();
    console.log("unfiltered deals", deals);
    // Filter the portfolios to show only those with hasPermission=true
    // const filteredFormats = formats.filter(format => format.hasPermission === true);

    setListings(deals);
    setLoading(false);
    console.log("filtered formats", deals);
  }
  useEffect(() => {
    setLoading(true);
    fetchDeals();
  }, []);

  if (loading) {
    return <LoaderSpinner show={loading} />;
  }

  return (
    <>
      <div style={{ float: 'right', width: '50%', textAlign: 'right' }}>
      </div>
      <Row>
                        {/* Main Deals Flow Tab  */}
        <Col xs={8}>
          <div className="row" style={{ borderBottom: '1px solid #EAECF0', paddingBottom: '5px' }}>
            <div className="col-md-7">
              <Title>Deals Flow</Title>
            </div>
            <div className="col-md-5 text-right" style={{ marginTop: '-17px', textAlign: 'right' }}>

            <Button variant="default" onClick={() => navigate('/dashboard/add-portfolio')} style={{  marginTop: '20px' }} label="Create Portfolio">Save Search</Button>
            </div>
          </div>
          <div className="listings-section">
            {listings.length > 0 ? (
              listings.map((listing, index) => (
                <Listing 
                  key={index} 
                  dealData={listing}
                  image={listing.portfolio_image ? listing.portfolio_image : listingImage} 
                  favourite={true} 
                  onDelete={fetchDeals} 
                />
              ))
            ) : (
              <Paragraph>No listings available</Paragraph>
            )}
          </div> 
        </Col>
      </Row>
    </>
  );
};

export default DealsFlow;
