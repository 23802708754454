import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from '../../Switch/SwitchSmall';
import Label from '../../Typorgraphy/Label';
import Paragraph from '../../Typorgraphy/Paragraph';
import './style.css';

const Notifications = () => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <p className="section-title" style={{ color: '#101828' }}>Notification Settings</p>
                    <Paragraph style={{ marginTop: '-10px' }}>We may still send you important notifications about your account outside of your notification settings.</Paragraph>
                </Col>
            </Row>
            <hr />

            <Row>
                <Col xs={5}>
                    <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Conversations</p>
                    <Paragraph>Notifications about Replies and updates on conversations.</Paragraph>
                </Col>
                <Col xs={6} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Push</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Email</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>SMS</Label>
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col xs={5}>
                    <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Marketplace</p>
                    <Paragraph>Notifications about Replies and updates on Marketplace.</Paragraph>
                </Col>
                <Col xs={6} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Push</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Email</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>SMS</Label>
                    </div>
                </Col>
            </Row>

            <hr />

            <Row>
                <Col xs={5}>
                    <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Portfolio</p>
                    <Paragraph>Notifications about Replies and updates on Portfolio updates .</Paragraph>
                </Col>
                <Col xs={6} >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Push</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>Email</Label>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Switch size="sm" checked />
                        <Label style={{ marginLeft: '15px', marginTop: '17px', fontSize: '16px' }}>SMS</Label>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default Notifications;